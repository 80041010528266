import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {Router} from 'react-router-dom';

import register, {unregister as unregisterServiceWorker} from './registerServiceWorker';
import configureStore, {history} from './store/configureStore.js';
import AppRoutes from './routes';
import 'core-js/stable';

const store = configureStore();
register();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Router>
  </Provider>,
  document.getElementById('root')
);

unregisterServiceWorker();
