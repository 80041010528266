import React, {Component} from 'react';
import logoAssets from 'assets/assets.js';
import ReactMarkdown from 'react-markdown';
import {css, StyleSheet} from 'aphrodite';
import {chunk} from 'lodash';

const queryString = require('query-string');

export class MedicalNecessityChecklistPage extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(
      window.location.search,
      {
        arrayFormat: 'bracket',
        parseBooleans: true,
      }
    );

    this.storageID = queryParams['id'] ? queryParams['id'] : '0';
  }

  _renderFillIntheBlanks(elements) {
    const groupedElements = chunk(elements, 2);

    return groupedElements.map((el, index) => {
      if (el.length === 2) {
        return (
          <div className="row" key={index}>
            <div className="col-xs-6" key={el[0]}><h4>{el[0]}:</h4></div>
            <div className="col-xs-6" key={el[1]}><h4>{el[1]}:</h4></div>
          </div>);
      } else {
        return (
          <div className="row" key={index}>
            <div className="col-xs-6" key={el[0]}><h4>{el[0]}:</h4></div>
          </div>);
      }
    });
  }

  _renderFields(elements, styles) {
    return elements.map((el, index) => {
      const style = styles[el.property] ? StyleSheet.create(styles[el.property]) : '';

      if (el.markdown) {
        return (
          <div key={index}>
            <div key={el.property} className={css(style.label)}>{el.label}</div>
            <div key='markdown{index}' className={css(style.text)}><ReactMarkdown escapeHtml={false} source={el.text}/></div>
            <br/>
          </div>
        );
      } else {
        return (
          <div key={index}>
            <div key={el.property} className={css(style.label)}>{el.label}</div>
            <div key='text{index}' className={css(style.text)}>{el.text}</div>
            <br/>
          </div>
        );
      }
    });
  }

  render() {
    const storageName = `medicalNecessityChecklist-${this.storageID}`;
    const data = JSON.parse(localStorage.getItem(storageName));
    localStorage.removeItem(storageName);
    const url = `${window.location.protocol}//${window.location.host}`;


    let html = (<h3>This page has expired. Please return to <a href={url}>{url}</a></h3>);

    if (data) {
      const styles = StyleSheet.create(data.medicalNecessityStyles);
      const fillInTheBlanks = this._renderFillIntheBlanks(data.fillInTheBlanks);

      html = (
        <div style={data.medicalNecessityStyles.bodySize} className={css(styles.tableStyle)}>
          <img src={logoAssets[data.header.logo]} style={data.header.style} alt=''/>
          <br/><br/>
          <button onClick={() => window.print()}>Print this page</button>
          {fillInTheBlanks}
          <h4><b>{data.title}</b></h4>
          <br/>
          {this._renderFields(data.fields, data.medicalNecessityStyles.fieldStyle)}
          <div className={css(styles.disclaimer)}>
            <ReactMarkdown escapeHtml={false} source={data.disclaimer}/>
          </div>
        </div>);
    }

    return (html);
  }
}

export default MedicalNecessityChecklistPage;