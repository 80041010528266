import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {Grid, Row, Col} from 'react-bootstrap';
import {css} from 'aphrodite';

import {nodeId} from 'react-widgets/libs';

import BoxedIsi from 'components/BoxedIsi';

const Footer = (props) => {
  const {settings, styleSheet, logoAssets} = props;

  const linkRenderer = (props) => {
    return (
      <a
        target={settings.footer.linkNewTab ? '_blank' : null}
        rel={settings.footer.linkNewTab ? 'noopener noreferrer' : null}
        href={props.href}
        className={css(styleSheet.footerLinkInNonLinkSection)}
      >
        {props.children}
      </a>
    );
  };

  let footerGridElements = [];
  let footerElementIndex = 0;
  settings.footer.layout.forEach((rowArray) => {
    let footerRowElements = [];
    rowArray.forEach((column) => {
      if (column.element === 'logo') {
        footerRowElements.push(
          <Col key={nodeId()} sm={column.gridWidth} className={`col-sm-offset-${column.offset} ${css(styleSheet[`footerElement${footerElementIndex}`])}`}>
            {settings.footer.logoLink ? (
              <a href={settings.footer.logoLink} target={settings.footer.logoLinkNewTab ? '_blank' : null}>
                <img src={logoAssets[settings.footer.logo.file]} className={`${css(styleSheet.footerLogo)} img-responsive` } alt="logo" />
              </a>) : (
              <img src={logoAssets[settings.footer.logo.file]} className={`${css(styleSheet.footerLogo)} img-responsive` } alt="logo" />)
            }
            {column.markdown &&
              <ReactMarkdown escapeHtml={false} source={column.markdown} renderers={{link: linkRenderer}} />
            }
          </Col>
        );
      } else if (column.element === 'links') {
        footerRowElements.push(
          <Col key={nodeId()} sm={column.gridWidth} className={`col-sm-offset-${column.offset} ${css(styleSheet[`footerElement${footerElementIndex}`])}`}>
            {settings.footer.links.map((link) => (<a key={nodeId()} className={css(styleSheet.footerLink)} href={link.href}>{link.name}</a>))}
          </Col>
        );
      } else if (column.element === 'content') {
        footerRowElements.push(
          <Col key={nodeId()} sm={column.gridWidth} className={`col-sm-offset-${column.offset} ${css(styleSheet[`footerElement${footerElementIndex}`])}`}>
            <ReactMarkdown escapeHtml={false} source={settings.footer.content.markdown} renderers={{link: linkRenderer}} />
          </Col>
        );
      } else if (column.element === 'additionalContent') {
        let additionalContentOutput = [];
        additionalContentOutput.push(<ReactMarkdown key={nodeId()}escapeHtml={false} source={column.markdown} renderers={{link: linkRenderer}}/>);
        if (column.html) {
          additionalContentOutput.push(<div key={nodeId()}dangerouslySetInnerHTML={{__html: column.html}} />);
        }
        footerRowElements.push(
          <Col key={nodeId()} sm={column.gridWidth} className={`col-sm-offset-${column.offset} ${css(styleSheet[`footerElement${footerElementIndex}`])}`}>
            {additionalContentOutput}
          </Col>
        );
      }
      footerElementIndex++;
    });
    footerGridElements.push(
      <Row key={nodeId()} className={css(styleSheet.footerRows)}>
        {footerRowElements}
      </Row>
    );
  });

  return (
    <footer data-component="Footer">
      <BoxedIsi
        enabled={!settings.isi.boxedIsi.disabled}
        settings={settings.isi}
        styleSheet={styleSheet}
        source={settings.isi.boxedIsi.content.markdown.length ? settings.isi.boxedIsi.content.markdown : settings.isi.content.markdown}
        topRightLink={{
          name: 'Important Safety Information',
          href: '#isi',
        }}
        fixed={settings.isi.boxedIsi.fixed}
        expandable={settings.isi.boxedIsi.expandable}
      />
      <div className={css(styleSheet.footer)}>
        <Grid>
          {footerGridElements}
        </Grid>
      </div>
    </footer>
  );
};

Footer.protoTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  logoAssets: PropTypes.object.isRequired,
};

export default Footer;
