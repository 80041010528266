import {Map, List} from 'immutable';
import {createGlobalErrorObj} from 'react-widgets/libs';
import {has} from 'lodash';

import {
  CLAIMNOC_ERROR,
  CLAIMNOC_REQUEST,
  GET_CLAIMNOC_DATA_SUCCESS,
  SET_CLAIMNOC_SHOW_ALERT,
  ERROR_ALERT,
  NO_ROW_ALERT,
} from '../actions/claimNOC';


function setClaimNOCError(state, action) {
  // Fallback to hardcoded default value if components did not dispatch an action with optional parameter to specify default errors
  let defaultError = action.defaultError
    ? action.defaultError
    : 'Unable to process your query at this time. Please try again later.';

  let error = has(action, 'error.data.error')
    ? action.error.data.error
    : createGlobalErrorObj([defaultError]).data.error;

  const requestCount = state.get('_metadata').get('inProgress');

  return state.set(
    '_metadata',
    Map({
      inProgress: requestCount - 1,
      error,
      showErrorAlert: true,
      showNoRowsAlert: false,
    })
  );
}

function setClaimNOCRequest(state) {
  const requestCount = state.get('_metadata').get('inProgress');

  return state.mergeDeep({
    _metadata: Map({
      inProgress: requestCount + 1,
    }),
  });
}

function setClaimNOCSuccess(state, action) {
  const {
    data,
    notes,
  } = action.payload;

  const claimNOCData = data.filter((x) => x.Content.trim() !== '');

  const notesData = notes.map((x) => {
    return [x.Label, x.Content];
  });

  const requestCount = state.get('_metadata').get('inProgress');

  return state.mergeDeep({
    claimNOCs: claimNOCData,
    notes: notesData,
    _metadata: Map({
      inProgress: requestCount - 1,
    }),
  });
}

function setClaimNOCShowAlert(state, action) {
  const {
    type,
    value,
  } = action.payload;

  if (type === ERROR_ALERT) {
    return state.mergeDeep({
      _metadata: {
        showErrorAlert: value,
      },
    });
  } else if (type === NO_ROW_ALERT) {
    return state.mergeDeep({
      _metadata: {
        showNoRowsAlert: value,
      },
    });
  }
}

export default function (state = Map({
  claimNOCs: List(),
  notes: List(),
  _metadata: Map({
    inProgress: 0,
    error: false,
    showErrorAlert: false,
    showNoRowsAlert: true,
  }),
}), action) {

  switch (action.type) {
    case GET_CLAIMNOC_DATA_SUCCESS:
      return setClaimNOCSuccess(state, action);

    case CLAIMNOC_REQUEST:
      return setClaimNOCRequest(state);

    case SET_CLAIMNOC_SHOW_ALERT:
      return setClaimNOCShowAlert(state, action);

    case CLAIMNOC_ERROR:
      return setClaimNOCError(state, action);

    default:
      return state;
  }
}
