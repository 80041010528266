import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {StyleSheet} from 'aphrodite';

import PalPage from '../Pal';

export class PalStyleWrapperPage extends Component {
  render() {
    const {
      settings,
    } = this.props;

    const styleSheetObject = {
      // Maybe style links via settings.page.link.style.color
      header: settings.styles.header,
      footer: settings.styles.footer,
      headerLogo: settings.styles.headerLogo,
      footerLogo: settings.styles.footerLogo,
      headerLink: settings.styles.headerLink,
      headerBar: settings.styles.headerBar,
      headerPageLink: settings.styles.headerPageLink,
      footerRows: settings.styles.footerRows,
      footerLink: settings.styles.footerLink,
      modalReject: settings.styles.modalReject,
      modalSuccess: settings.styles.modalSuccess,
      modal: settings.styles.modal,
      interstitialModalReject: settings.styles.interstitialModalReject,
      interstitialModalSuccess: settings.styles.interstitialModalSuccess, // Maybe can be combined into a single button class
      coverageSummaryModal: settings.styles.coverageSummaryModal, // fontSize may be redundant with coverageSummaryModalLabel
      coverageSummaryModalFooter: settings.styles.coverageSummaryModalFooter,
      loginButton: settings.styles.loginButton, // Maybe can be combined into a single button class
      isi: settings.styles.isi,
      boxedIsi: settings.styles.boxedIsi,
      topRightLink: settings.styles.topRightLink,
      topRightExpandToggle: settings.styles.topRightExpandToggle,
      fixedIsi: settings.styles.fixedIsi,
      isiShrunk: settings.styles.isiShrunk,
      isiExpanded: settings.styles.isiExpanded,
      faq: settings.styles.faq,
      searchButton: settings.styles.searchButton,
      footNote: settings.styles.footNote,
      results: settings.styles.results,
      highlightRow: settings.styles.highlightRow,
      highlightRowPara: settings.styles.highlightRowPara,
      highlightRowDisclaimer: settings.styles.highlightRowDisclaimer,
      dataTable: settings.styles.dataTable,
      stateCoverageProfileDocumentBox: settings.styles.stateCoverageProfileDocumentBox,
      coverageSummaryModalLabel: settings.styles.coverageSummaryModalLabel,
      isiContentLink: settings.styles.isiContentLink,
      isiContentBold: settings.styles.isiContentBold,
      boxedIsiSpecialRenderer: settings.styles.boxedIsiSpecialRenderer,
      initialTherapyCell: settings.styles.initialTherapyCell,
      initialTherapyColumn: settings.styles.initialTherapyColumn,
      continuedTherapyCell: settings.styles.continuedTherapyCell,
      continuedTherapyColumn: settings.styles.continuedTherapyColumn,
      faqPreface: settings.styles.faqPreface,
      faqLink: settings.styles.faqLink,
      footerLinkInNonLinkSection: settings.styles.footerLinkInNonLinkSection,
      modalBackdrop: settings.styles.modalBackdrop,
      stateCoverageProfileDocumentText: settings.styles.stateCoverageProfileDocumentText,
      stateCoverageProfileDocumentLink: settings.styles.stateCoverageProfileDocumentLink,
      initialSearchInstruction: settings.styles.initialSearchInstruction,
      loginInstruction: settings.styles.loginInstruction,
      loginContent: settings.styles.loginContent,
      medicalNecessityChecklist: settings.styles.medicalNecessityChecklist,
      tooltip: settings.styles.tooltip,
      contactInstructionsModal: settings.styles.contactInstructionsModal,
      contactInstructionsModalLabel: settings.styles.contactInstructionsModalLabel,
      searchPreface: settings.styles.searchPreface,
      loginBackground: settings.styles.loginBackground,
      underResultsText: settings.styles.underResultsText,
      underFiltersText: settings.styles.underFiltersText,
      bottomSection: settings.styles.bottomSection,
      banner: settings.styles.banner,
      linkOutLabel: settings.styles.linkOutLabel,
      dropdownSelectorDropdown: settings.styles.dropdownSelectorDropdown,
      dropdownSelectorToggle: settings.styles.dropdownSelectorToggle,
      dropdownSelectorButton: settings.styles.dropdownSelectorButton,
    };

    settings.styles.tableColumns.forEach((column) => {
      styleSheetObject[`tableColumn${column.property}`] = column.style;
    });

    let footerElementIndex = 0;
    settings.styles.footerLayout.forEach((rowArray) => {
      rowArray.forEach((column) => {
        styleSheetObject[`footerElement${footerElementIndex++}`] = column.style;
      });
    });

    settings.styles.coverageSummaryModalBody.forEach((column) => {
      styleSheetObject[`coverageSummaryModalBody${column.property}`] = column.style;
    });

    settings.styles.contactInstructionsModalBody.forEach((column) => {
      styleSheetObject[`contactInstructionsModalBody${column.property}`] = column.style;
    });

    const styleSheet = StyleSheet.create(styleSheetObject);

    return (<PalPage styleSheet={styleSheet}/>);
  }
}

PalStyleWrapperPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  const {
    settings,
  } = state;

  return {
    settings: settings.get('settings').toJS(),
  };
};

export default connect(
  mapStateToProps,
)(withRouter(PalStyleWrapperPage));
