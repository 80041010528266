/**
 * Action types
 */
export const SETTINGS_ERROR       = 'SETTINGS_ERROR';
export const SETTINGS_REQUEST     = 'SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';

export function settingsRequest() {
  return {
    type: SETTINGS_REQUEST,
  };
}

export function settingsError(error) {
  return {
    type: SETTINGS_ERROR,
    error: error.response,
  };
}

export function getSettings(domainId) {
  return function (dispatch, getState, api) {
    dispatch(settingsRequest());
    return api.get(`/settings/${domainId}`)
      .then(({data}) => {
        dispatch(getSettingsSuccess({
          settings: {domain: domainId, ...data},
        }));
      })
      .catch((error) => dispatch(settingsError(error)));
  };
}

export function getLocalSettings(domainId) {
  return function (dispatch, getState, api) {
    dispatch(settingsRequest());

    //When dynamically load a file, Webpack by default generate a chunk for that module
    //Eager webpack mode forces Webpack to include the file chunk inside the parent bundle/chunk
    //forcing it to not create a separated chunk js
    let localSettings = import(/* webpackMode: "eager" */`../../json-style-files/${domainId}.json`);

    return localSettings
      .then((data) => {
        dispatch(getSettingsSuccess({
          settings: {domain: domainId, ...data},
        }));
      })
      .catch((error) => dispatch(settingsError(error)));
  };
}

export function getSettingsSuccess(data) {
  return {
    type: GET_SETTINGS_SUCCESS,
    payload: data,
  };
}
