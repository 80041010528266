import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {css} from 'aphrodite';
import ReactMarkdown from 'react-markdown';
import {
  isEqual,
} from 'lodash';
import {
  Button,
  Grid,
  Row,
  Col,
  Alert,
} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
  findIconDefinition,
} from '@fortawesome/fontawesome-svg-core';

import {
  ExitModal,
} from 'components/Modals';

import {
  getClaimNOCList,
  setClaimNOCShowAlert,
  ERROR_ALERT,
  NO_ROW_ALERT,
} from 'actions/claimNOC';

import {
  getError,
  getInProgress,
  getShowNoRowsAlert,
  getShowErrorAlert,
} from 'selectors/claimNOC';

import {
  filterForMapStates,
} from 'libs/salmon-utils';

import Isi from 'components/Isi';
import {getSettings} from 'selectors/settings';
import {nodeId, getErrorField, hasClass} from 'react-widgets/libs';
import {MultiSelect, DataTable, Loader, ErrorAlert, USMap} from 'react-widgets/components';
import {USStates} from 'react-widgets/components/USMap';

import './styles.scss';
import {Banner} from '../Banner';

export class LinkNOC extends Component {
  constructor(props) {
    super(props);

    const tableColumns = props.settings.table.columns.map((column) => {
      let dataTableColumn = {
        property: column.property,
        sortable: column.sortable,
        header: {
          label: column.label,
        },
      };

      let styleName = `tableColumn${column.property}`;

      if (props.styleSheet[styleName]) {
        dataTableColumn.props = {
          className: css(props.styleSheet[styleName]),
        };
      }

      // Special column-specific formatting defined here.
      if (column.property === 'nocBillingInstructions') {
        dataTableColumn.cell = {
          formatters: [
            (value, {rowData}) => {
              if (!value) {
                return;
              }
              const nocLinks = [];
              const iconFile = findIconDefinition({prefix: 'fas', iconName: 'file'});
              for (const [label, link, ...notes] of value) {
                let displayNotes = notes[0].map((x) => {
                  return (<p key={nodeId()}>{x}</p>);
                });
                nocLinks.push(
                  <h5 key={nodeId()} >
                    <a onClick={this.handleOutgoingClick} href={link}><FontAwesomeIcon icon={iconFile}/>{label}</a>{displayNotes}
                  </h5>);
              }
              return (
                <div>
                  {nocLinks}
                </div>
              );
            },
          ],
        };
      } else if (column.property === 'marketPolicy') {
        dataTableColumn.cell = {
          formatters: [
            (value, {rowData}) => {
              if (!value) {
                return;
              }
              const marketPolicyLinks = [];
              const iconFile = findIconDefinition({prefix: 'fas', iconName: 'file'});
              for (const [label, link, ...notes] of value) {
                let displayNotes = notes[0].map((x) => {
                  return (<p key={nodeId()}>{x}</p>);
                });
                marketPolicyLinks.push(
                  <h5 key={nodeId()}>
                    <a onClick={this.handleOutgoingClick} href={link}><FontAwesomeIcon icon={iconFile}/>{label}</a>{displayNotes}
                  </h5>);
              }
              return (
                <div>
                  {marketPolicyLinks}
                </div>
              );
            },
          ],
        };
      }

      return dataTableColumn;
    });

    this.state = {
      // Fields denoting what User is searching form.
      selectedPayer: [],
      selectedStates: [],
      // Options for each select dropdown.
      payerList: [],
      // Results table fields.
      tableColumns: tableColumns,
      tableRows: [],
      // Modal settings.
      showExitModal: false,
      outGoingLink: '',
    };

    this.handleSearchFilterChange = this.handleSearchFilterChange.bind(this);
    this.filterEntries = this.filterEntries.bind(this);
    this.clearEntries = this.clearEntries.bind(this);
    this.handleOutgoingClick = this.handleOutgoingClick.bind(this);
    this.handleOutgoingOpen = this.handleOutgoingOpen.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleUSMapClick = this.handleUSMapClick.bind(this);
  }

  componentDidMount() {
    const {
      dispatchGetClaimNOCList,
      settings,
    } = this.props;

    // Don't fetch noc data again if component remounts.
    if (!this.props.claimNOCs.length) {
      dispatchGetClaimNOCList(settings.domain, settings.dataTable.searchErrorUnavailable);
    }
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      claimNOCs,
    } = this.props;

    if (isEqual(prevState, this.state) && isEqual(prevProps, this.props)) {
      return;
    }

    const payersSet = new Set();
    // Populate the selector options.
    if (claimNOCs.length && !this.state.payerList.length) {
      claimNOCs.forEach((claim) => {
        if (claim['Payer']) {
          payersSet.add(claim['Payer']);
        }
      });

      this.filterEntries();
      this.setState({
        payerList: [...payersSet].sort(),
      });
    }
  }

  handleSearchFilterChange(selected, type) {
    if (type === 'claimProcessor') {
      this.setState({
        selectedPayer: selected,
      });
    }
    if (type === 'states') {
      this.setState({
        selectedStates: selected,
      });
    }
  }

  clearEntries() {
    this.setState({
      selectedStates: [],
      selectedPayer: [],
    });
  }

  filterEntries(e) {
    const {
      selectedPayer,
      selectedStates,
    } = this.state;

    let tableData = [];
    let payers = {};

    // For each payer create an object
    // payer {
    //    nocBillingInstructions: [[label, link, notes], ...],
    //    marketPolicy: [[label, link, notes], ...],
    //    states: [],
    // }
    this.props.claimNOCs.forEach((claim) => {
      let linkColumn = 'nocBillingInstructions';
      if (claim.LinkType.trim() === 'Market Policy') {
        linkColumn = 'marketPolicy';
      }

      // Return a reference to the array or initalize it if it doesn't exist.
      let payerReference = payers[claim.Payer] ? payers[claim.Payer] : payers[claim.Payer] = {nocBillingInstructions: [], marketPolicy: [], states: new Set()};

      if (claim.States) {
        let claimStates = claim.States.split(',').map((x) => x.trim());
        payerReference['states'] = new Set([...payerReference['states'], ...claimStates]);
      }

      payerReference[linkColumn].push([
        claim.Label,
        claim.Content,
        // Replace the numeric code with text, removing any undefined values.
        claim.Notes.split(',').map((x) => this.props.notes[x.trim()]).filter(Boolean),
      ]);
    });

    for (const payerName of Object.keys(payers).sort()) {
      // If we are filtering by states, skip over this payer if its state is not selected.
      if (selectedStates.length) {
        let intersection = new Set([...selectedStates.filter((x) => payers[payerName].states.has(x.id))]);
        if (!intersection.size) {
          continue;
        }
      }

      if (selectedPayer.length === 0 || payerName === selectedPayer[0]) {
        tableData.push({
          id: nodeId(),
          claimProcessor: payerName,
          nocBillingInstructions: payers[payerName].nocBillingInstructions,
          marketPolicy: payers[payerName].marketPolicy,
        });
      }
    }

    this.setState({
      tableRows: tableData,
    }, () => {
      this.displayAlert(NO_ROW_ALERT, true);
    });

    // If search button was clicked, set user focus to the tableData.
    if (e && e.target.value === 'Search' && this.props.settings.jumpToResultsAfterSearching) {
      document.getElementById('dataTable').scrollIntoView();
    }

  }

  handleOutgoingClick(e) {
    e.preventDefault();

    this.setState({
      showExitModal: true,
      outGoingLink: e.currentTarget.href,
    });
  }

  handleOutgoingOpen() {
    window.open(this.state.outGoingLink, '_blank');

    this.setState({
      showExitModal: false,
    });
  }

  handleHide() {
    this.setState({
      showExitModal: false,
    });
  }

  handleUSMapClick(el) {
    const {
      selectedStates,
    } = this.state;

    const currentElement = el.currentTarget;
    let include = !hasClass(currentElement, 'active');

    let currentSelectedStates = filterForMapStates(selectedStates, currentElement.id, include, USStates);

    this.setState({
      selectedStates: currentSelectedStates,
    });
  }

  displayAlert(type, value) {
    this.props.dispatchSetClaimNOCShowAlert({
      type,
      value,
    });
  }

  render() {
    const {
      payerList,
      selectedPayer,
      selectedStates,
      tableColumns,
      tableRows,
      showExitModal,
    } = this.state;

    const {
      inProgress,
      settings,
      styleSheet,
      showTable,
      showNoRowsAlert,
      showErrorAlert,
      error,
    } = this.props;

    let filterInputs = [];
    settings.filterLayout.forEach((rowArray) => {
      let filterRowElements = [];
      rowArray.forEach((column) => {
        if (column.filter === 'claimProcessor') {
          filterRowElements.push(
            <Col id={nodeId()} key={nodeId()} style={{fontSize: 200}} sm={column.gridWidth} className={`col-sm-offset-${column.offset}`}>
              <MultiSelect
                label={column.label}
                placeholder={column.placeholder}
                options={payerList}
                multiple={false}
                clearButton={true}
                selected={selectedPayer}
                isFetching={inProgress}
                handleChange={this.handleSearchFilterChange}
                selectHintOnEnter={false}
                type={'claimProcessor'}/>
            </Col>
          );
        }
        if (column.filter === 'state') {
          filterRowElements.push(
            <Col id={nodeId()} key={nodeId()} style={{fontSize: 200}} sm={column.gridWidth} className={`col-sm-offset-${column.offset}`}>
              <MultiSelect
                label={column.label}
                placeholder={column.placeholder}
                options={USStates}
                selected={selectedStates}
                isFetching={inProgress}
                handleChange={this.handleSearchFilterChange}
                type={'states'}
              />
            </Col>
          );
        }
      });
      filterInputs.push(...filterRowElements);
    });

    return (
      <div data-component={'ClaimNOC'}>
        <Loader isFetching={inProgress} isFullscreen/>
        <ExitModal
          settings={settings}
          styleSheet={styleSheet}
          showExitModal={showExitModal}
          handleHide={this.handleHide}
          handleOpenDocument={this.handleOutgoingOpen}
        />

        <Grid>
          <Row className={css(styleSheet.preface)}>
            <ErrorAlert error={getErrorField('global', 'global', error)} alertVisible={showErrorAlert} handleDismissAlert={this.displayAlert.bind(this, ERROR_ALERT, false)}/>
          </Row>
          <Row>
            { Boolean(settings.banner.enabled) &&
            <Col xs={12} className={css(styleSheet.banner)}>
              { settings.banner.content.tab &&
                <Row>
                  <Col>
                    <ul className={css(styleSheet.bannerTabs)}>
                      <li><span>{settings.banner.content.tab}</span></li>
                    </ul>
                  </Col>
                </Row>
              }
              { Boolean(settings.banner.content.html) &&
                <div dangerouslySetInnerHTML={{__html: settings.banner.content.html}} />
              }
              { Boolean(settings.banner.content.component) &&
                <Banner
                  {...settings.banner.content.component}
                ></Banner>
              }
            </Col>
            }
          </Row>
          <Row>
            <Col xs={styleSheet.initialSearchInstruction._definition.colWidth} className={`instruction ${css(styleSheet.initialSearchInstruction)}`}>
              {
                settings.dataTable.initialSearchInstruction &&
                <ReactMarkdown escapeHtml={false} source={settings.dataTable.initialSearchInstruction}/>
              }
            </Col>
            <Col>
              {filterInputs}
              { settings.clearButton &&
                <Button type={'reset'} className={css(styleSheet.modalSuccess, styleSheet.clearButton)} onClick={this.clearEntries}>Clear
                </Button>
              }
              <Button type={'submit'} className={css(styleSheet.modalSuccess, styleSheet.searchButton)} onClick={this.filterEntries} value='Search'>Search</Button>
            </Col>
          </Row>
          {
            settings.usRegionalMap &&
            <Row>
              <Col>
                <USMap
                  handleStateClick={this.handleUSMapClick}
                  selectedStates={selectedStates}
                />
              </Col>
            </Row>
          }
          { showTable && tableColumns &&
            <Row className={css(styleSheet.results)} id='dataTable'>
              <Row>
                { tableRows.length !== 0 &&
                  <div className={css(styleSheet.dataTable)}>
                    <DataTable
                      rows={tableRows}
                      columns={tableColumns}
                      centerPagination={true}
                      perPage={settings.dataTable.perPage}
                    />
                  </div>
                }
              </Row>
              {
                (!inProgress && tableRows.length === 0 && showNoRowsAlert) &&
                <div>
                  <br/>
                  <Row>
                    <Alert bsStyle={'warning'} onDismiss={this.displayAlert.bind(this, NO_ROW_ALERT, false)}>{settings.dataTable.errorNoResult}</Alert>
                  </Row>
                </div>
              }
            </Row>
          }
          <Isi settings={settings.isi} styleSheet={styleSheet}/>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = function (state, ownProps) {
  const {
    claimNOC,
  } = state;

  return {
    claimNOCs: claimNOC.get('claimNOCs').toJS(),
    notes: Object.fromEntries(claimNOC.get('notes').toJS()),
    inProgress: getInProgress(state),
    settings: getSettings(state),
    showNoRowsAlert: getShowNoRowsAlert(state),
    showErrorAlert: getShowErrorAlert(state),
    error: getError(state),
  };

};

LinkNOC.propTypes = {
  dispatchGetClaimNOCList: PropTypes.func.isRequired,
  dispatchSetClaimNOCShowAlert: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  claimNOCs: PropTypes.array.isRequired,
  inProgress: PropTypes.bool,
  showNoRowsAlert: PropTypes.bool.isRequired,
  showErrorAlert: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
};

const mapDispatchToProps = function (dispatch) {
  return {
    dispatchGetClaimNOCList: (...args) => {
      dispatch(getClaimNOCList(...args));
    },
    dispatchSetClaimNOCShowAlert: (...args) => {
      dispatch(setClaimNOCShowAlert(...args));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkNOC);

