import {Map} from 'immutable';
import {createGlobalErrorObj} from 'react-widgets/libs';

import {
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_RESET_ALERT,
} from '../actions/login';


function setLoginRequest(state) {
  return state.merge({
    _metadata: Map({
      error: false,
      inProgress: true,
      showErrorAlert: false,
    }),
  });
}

function resetAlert(state) {
  return state.mergeDeep({
    _metadata: Map({
      showErrorAlert: false,
    }),
  });
}

function setLogoutRequest(state) {
  return state.merge({
    authenticated: false,
  });
}

function setLoginError(state, action) {
  // Fallback to hardcoded default value if components did not dispatch an action with optional parameter to specify default errors
  let defaultError = action.defaultError ? action.defaultError : 'The service is temporarily unavailable. Please check back soon.';
  let error = (action.error !== undefined && action.error.hasOwnProperty('data')) ?
    action.error.data.error :
    createGlobalErrorObj([defaultError]).data.error;

  return state.merge({
    _metadata: Map({
      error,
      inProgress: false,
      showErrorAlert: true,
    }),
    authenticated: false,
  });
}

function setLoginSuccess(state, action) {
  // response is not json, is just a string
  const authenticated = action.payload;

  // Fallback to hardcoded default value if components did not dispatch an action with optional parameter to specify login fail errors
  let loginFailError = action.loginFailError ? action.loginFailError : 'The service is temporarily unavailable. Please check back soon.';
  let error = authenticated ? false : createGlobalErrorObj([loginFailError]).data.error;

  return state.merge({
    _metadata: Map({
      error,
      inProgress: false,
      showErrorAlert: !!error,
    }),
    authenticated,
  });
}

export default function (state = Map({
  _metadata: Map({
    error: false,
    inProgress: false,
    showErrorAlert: false,
  }),
  authenticated: false,
}), action) {

  switch (action.type) {
    case LOGIN_REQUEST:
      return setLoginRequest(state);

    case LOGIN_RESET_ALERT:
      return resetAlert(state);

    case LOGOUT_REQUEST:
      return setLogoutRequest(state);

    case LOGIN_SUCCESS:
      return setLoginSuccess(state, action);

    case LOGIN_ERROR:
      return setLoginError(state, action);

    default:
      return state;
  }
}
