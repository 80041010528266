import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {createBrowserHistory as createHistory} from 'history';

/** Handle ajax requests */
import api from '../libs/api';

/** Import combined reducers */
import reducer from '../reducers';

export const history = createHistory();

export default function configureStore(initialState) {
  let middlewares = (process.env.NODE_ENV !== 'production')
    ? [thunk.withExtraArgument(api), createLogger()]
    : [thunk.withExtraArgument(api)];

  middlewares = [...middlewares];

  const finalCreateStore = compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  )(createStore);

  const store = finalCreateStore(reducer, initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers');
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
