import {combineReducers} from 'redux';

import login from './login';
import claimNOC from './claimNOC';
import coverage from './coverage';
import settings from './settings';

export default combineReducers({
  login,
  claimNOC,
  coverage,
  settings,
});
