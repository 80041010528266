/**
 * Action types
 */

export const COVERAGE_ERROR                         = 'COVERAGE_ERROR';
export const COVERAGE_REQUEST                       = 'COVERAGE_REQUEST';

export const GET_LIST_DATA_SUCCESS  = 'GET_LIST_DATA_SUCCESS';
export const GET_STATE_COVERAGE_PROFILES_SUCCESS    = 'GET_STATE_COVERAGE_PROFILES_SUCCESS';
export const SET_STATE_COVERAGE_SHOW_ALERT = 'SET_STATE_COVERAGE_SHOW_ALERT';

export const ERROR_ALERT = 'ERROR_ALERT';
export const NO_ROW_ALERT = 'NO_ROW_ALERT';

export function stateCoverageProfilesRequest() {
  return {
    type: COVERAGE_REQUEST,
  };
}

export function setStateCoverageShowAlert(data) {
  return {
    type: SET_STATE_COVERAGE_SHOW_ALERT,
    payload: data,
  };
}

export function getStateCoverageProfiles(domainId) {
  return function (dispatch, getState, api) {
    dispatch(stateCoverageProfilesRequest());

    return api.get(`/statecoverageprofiles/${domainId}`)
      .then(({data}) => {
        dispatch(getStateCoverageProfilesSuccess({
          stateCoverageProfiles: Object.keys(data).map((state) => ({state, document: data[state]})),
          domain: domainId,
        }));
      })
      .catch((error) => dispatch(coverageError(error)));
  };
}

export function getStateCoverageProfilesSuccess(data) {
  return {
    type: GET_STATE_COVERAGE_PROFILES_SUCCESS,
    payload: data,
  };
}

export function coverageRequest() {
  return {
    type: COVERAGE_REQUEST,
  };
}

export function coverageError(error, defaultError = null) {
  return {
    type: COVERAGE_ERROR,
    error: error.response,
    defaultError,
  };
}

export function getStatePayerCoverageList(drugIdentifier, defaultError) {
  return function (dispatch, getState, api) {
    dispatch(coverageRequest());

    return api.get(`/drugs/${drugIdentifier}`)
      .then(({data}) => {
        if (!data.medData) {
          dispatch(coverageError({response: null}, defaultError));
        } else {
          dispatch(getStatePayerCoverageListSuccess({
            stateList: data.states,
            payerList: data.payers,
            indicationList: data.indications,
            planTypeList: data.plantypes,
            medData: data.medData,
            rxData: data.rxData,
            lastrefresheddates: data.lastrefresheddates,
          }));
        }
      })
      .catch((error) => dispatch(coverageError(error, defaultError)));
  };
}

export function getStatePayerCoverageListSuccess(data) {
  return {
    type: GET_LIST_DATA_SUCCESS,
    payload: data,
  };
}
