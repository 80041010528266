import {Map, List} from 'immutable';
import {createGlobalErrorObj} from 'react-widgets/libs';
import {has, sortBy} from 'lodash';
import {
  formatMedCoveragePosition,
  formatRxCoveragePositions
} from 'libs/salmon-utils';

import {
  COVERAGE_ERROR,
  COVERAGE_REQUEST,
  GET_LIST_DATA_SUCCESS,
  GET_STATE_COVERAGE_PROFILES_SUCCESS,
  SET_STATE_COVERAGE_SHOW_ALERT,
  ERROR_ALERT,
  NO_ROW_ALERT,
} from '../actions/coverage';

function setCoverageRequest(state) {
  const requestCount = state.get('_metadata').get('inProgress');

  return state.merge({
    _metadata: Map({
      error: false,
      inProgress: requestCount + 1,
      showErrorAlert: false,
      showNoRowsAlert: false,
    }),
  });
}

function setStateCoverageShowAlertSucess(state, action) {
  const {
    type,
    value,
  } = action.payload;

  if (type === ERROR_ALERT) {
    return state.mergeDeep({
      _metadata: {
        showErrorAlert: value,
      },
    });
  } else if (type === NO_ROW_ALERT) {
    return state.mergeDeep({
      _metadata: {
        showNoRowsAlert: value,
      },
    });
  }
}

function setCoverageError(state, action) {
  // Fallback to hardcoded default value if components did not dispatch an action with optional parameter to specify default errors
  let defaultError = action.defaultError
    ? action.defaultError
    : 'Unable to process your query at this time. Please try again later.';

  let error = has(action, 'error.data.error')
    ? action.error.data.error
    : createGlobalErrorObj([defaultError]).data.error;

  const requestCount = state.get('_metadata').get('inProgress');

  return state.merge({
    _metadata: Map({
      inProgress: requestCount - 1,
      error,
      showErrorAlert: true,
      showNoRowsAlert: false,
    }),
  });
}

function setStateCoverageListSuccess(state, action) {
  const {stateCoverageProfiles} = action.payload;
  const requestCount = state.get('_metadata').get('inProgress');

  return state.merge({
    stateCoverageProfiles: List(stateCoverageProfiles),
    _metadata: state.get('_metadata').merge({
      inProgress: requestCount - 1,
    }),
  });
}

function setStatePayerCoverageListSuccess(state, action) {
  const {
    stateList,
    planTypeList,
    payerList,
    indicationList,
    medData,
    rxData,
    lastrefresheddates,
  } = action.payload;

  let planTypes = [];
  const usStates = (stateList).map((s) => ({id: s.id, label: s.name}));
  const payers = (payerList).map((p) => ({id: p.id, label: p.name}));
  const indications = (indicationList).map((i) => ({id: i.id, label: i.name}));
  const formattedRxData = formatRxCoveragePositions(rxData);
  const formattedMedData = medData.map(formatMedCoveragePosition);

  const coveragePositions = sortBy(
    [...formattedMedData, ...formattedRxData],
    (position) => position.payer.toLowerCase()
  );

  //For Coverage Product dropdown
  const products = [
    ...new Map(coveragePositions
      .map( (c) => ({id: c.product.id, label: c.product.name})) //Get all products id & name
      .map( (c) => [c.id, c]) //Remove duplicated product
    )
      .values(),
  ];

  const addSelfAndChildren = (ptObj, nestLevel = 0) => {
    ptObj.nestLevel = nestLevel;
    nestLevel += 1;

    planTypes.push(ptObj);

    let children = planTypeList.filter((row) => row.parentid === ptObj.id);
    children.forEach((child) => addSelfAndChildren(child, nestLevel));
  };

  let topLevelPlanTypes = planTypeList.filter((row) => row.parentid === null);
  topLevelPlanTypes.forEach((pt) => addSelfAndChildren(pt));

  planTypes = planTypes.map((p) => ({id: p.id, label: p.name, parentname: p.parentname, nestLevel: p.nestLevel}));

  const requestCount = state.get('_metadata').get('inProgress');

  return state.merge({
    coveragePositions: List(coveragePositions),
    usStates: List(usStates),
    planTypes: List(planTypes),
    payers: List(payers),
    indications: List(indications),
    products: List(products),
    // add backwards compatibility with data without refresh dates
    lastrefresheddates: lastrefresheddates ? lastrefresheddates : List(),
    _metadata: Map({
      inProgress: requestCount - 1,
      error: false,
      showErrorAlert: false,
      showNoRowsAlert: true,
    }),
  });
}

export default function (state = Map({
  stateCoverageProfiles: List(),
  coveragePositions: List(),
  usStates: List(),
  planTypes: List(),
  payers: List(),
  indications: List(),
  products: List(),
  lastrefresheddates: List(),
  _metadata: Map({
    inProgress: 0,
    error: false,
    showErrorAlert: false,
    showNoRowsAlert: true,
  }),
}), action) {

  switch (action.type) {
    case COVERAGE_REQUEST:
      return setCoverageRequest(state);

    case COVERAGE_ERROR:
      return setCoverageError(state, action);

    case GET_LIST_DATA_SUCCESS:
      return setStatePayerCoverageListSuccess(state, action);

    case GET_STATE_COVERAGE_PROFILES_SUCCESS:
      return setStateCoverageListSuccess(state, action);

    case SET_STATE_COVERAGE_SHOW_ALERT:
      return setStateCoverageShowAlertSucess(state, action);

    default:
      return state;
  }
}
