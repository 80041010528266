import React, {useState} from 'react';
import {Dropdown, MenuItem, Button} from 'react-bootstrap';
import {css} from 'aphrodite';
import './styles.scss';
import {nodeId} from 'react-widgets/libs';

const DropdownSelector = ({
  id,
  menuItems,
  placeholderText = 'Select Product',
  buttonLabel = 'Go',
  newTab = false,
  onConfirmCallBack = null,
  menuItemCallBack,
  // Styles
  dropdownStyle,
  dropdownSelectorStyle,
  menuItemStyle,
  gobuttonStyle,
  dropdownToggleStyle
}) => {

  const [selectedMenuItem, setMenuItem] = useState(placeholderText);
  const [selectedHref, setHref] = useState('');

  const _handleSelect = (eventKey, event) => {
    setMenuItem(eventKey);
    setHref(event.target.getAttribute('href'));
    event.preventDefault();
  };

  const _handleGoButton = (newTab, onConfirmCallBack) => {
    const confirmed = onConfirmCallBack ? onConfirmCallBack() : true;
    if (confirmed && selectedHref) {
      if (newTab) {
        window.open(selectedHref, '_blank');
      } else {
        window.location.href = selectedHref;
      }
    }
  };

  return (
    <div data-component={'DropdownSelector'} className={dropdownSelectorStyle}>
      <Dropdown id={id} onSelect={_handleSelect} className={`dropdown ${dropdownStyle}`}>
        <Dropdown.Toggle className={`dropdowntoggle ${dropdownToggleStyle}`} noCaret>
          {selectedMenuItem}
          <i className="fa-solid fa-caret-down"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`menuitem ${menuItemStyle}`}>
          {menuItems.map(({label, url}) => (
            <MenuItem key={nodeId()} href={url} eventKey={label} onSelect={menuItemCallBack}>{label}</MenuItem>
          ))}
        </Dropdown.Menu>
        <Button
          className={`gobutton ${gobuttonStyle}`}
          onClick={() => _handleGoButton(newTab)}
        >{buttonLabel}</Button>
      </Dropdown>
    </div>
  );
};

export default DropdownSelector;