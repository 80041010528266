import {createSelector} from 'reselect';
import {getSettings} from 'selectors/settings';

const getRefreshedDates = (state) => state.coverage.get('lastrefresheddates');
const getShowErrorState = (state) => state.coverage.get('_metadata').get('showErrorAlert');
const getShowNoRowsState = (state) => state.coverage.get('_metadata').get('showNoRowsAlert');

export const getError = (state) => state.coverage.get('_metadata').get('error');
export const getInProgress = (state) => !!state.coverage.get('_metadata').get('inProgress');

export const getShowErrorAlert = createSelector(
  [getShowErrorState, getError],
  (showErrorAlert, error) => showErrorAlert && !!error
);

export const getShowNoRowsAlert = createSelector(
  [getShowNoRowsState, getError],
  (showNoRowsAlert, error) => showNoRowsAlert && !error
);

export const getLastRefreshedDate = createSelector(
  [getRefreshedDates, getSettings],
  (lastRefreshedDates, settings) => {
    let refreshedDate = '';
    if (lastRefreshedDates.length) {
      const {lastrefresheddate} = lastRefreshedDates.findLast((entry) => (entry.productid === settings.drugId)) || {};
      if (lastrefresheddate) {
        // formats ISO 8601 date to mm/dd/yyyy
        const event = new Date(lastrefresheddate);

        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          timeZone: 'UTC',
        };

        refreshedDate = event.toLocaleDateString('en-US', options);
      }
    }
    return refreshedDate;
  }
);
