import {Map} from 'immutable';
import {has}  from 'lodash';

import {
  SETTINGS_ERROR,
  SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
} from '../actions/settings';

function setSettingsRequest(state) {
  return state.merge({
    _metadata: Map({
      error: false,
    }),
  });
}

function setSettingsError(state, action) {
  let error;

  // Settings lambda is only expected to return 500 or 404 responses
  // Anything else is likely to be caused by client or network level blocking
  if (has(action, 'error.status')) {
    if (action.error.status === 404) {
      error = 'This page currently does not exist.';
    } else if (action.error.status === 500) {
      error = 'Unable to process your query at this time. Please try again later.';
    } else {
      error = 'Unable to establish connection to https://policyacumen.health/api, please check firewall and network.';
    }
  } else {
    error = 'Unable to establish connection to https://policyacumen.health/api, please check firewall and network.';
  }

  return state.merge({
    _metadata: Map({
      error,
    }),
  });
}

function setSettingsSuccess(state, action) {
  const {
    settings,
  } = action.payload;

  return state.merge({
    settings: Map(settings),
    _metadata: Map({
      error: false,
    }),
  });
}

export default function (state = Map({
  settings: Map(),
  _metadata: Map({
    error: false,
  }),
}), action) {

  switch (action.type) {
    case SETTINGS_REQUEST:
      return setSettingsRequest(state);

    case GET_SETTINGS_SUCCESS:
      return setSettingsSuccess(state, action);

    case SETTINGS_ERROR:
      return setSettingsError(state, action);

    default:
      return state;
  }
}
