import axios from 'axios';
import {extend} from 'lodash';

/** Handle ajax requests */
const api = axios.create({
  baseURL: window.env.apiResource,
  timeout: 30000,
  headers: {
    post: {
      'Content-Type': 'application/json',
    },
    common: {
      'Accept': 'application/json', // eslint-disable-line quote-props
    },
  },
});

api.interceptors.response.use((response) => {
  // Do something with response data
  return response;
}, (error) => {
  if (window.Rollbar) {
    window.Rollbar.error('React error', [error]);
  }

  return Promise.reject(error);
});

export default api;

export function setApiDefaults(defaults, api) {
  (api || axios).defaults = extend(axios.defaults, defaults);
}
