import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import {css} from 'aphrodite';
import ReactMarkdown from 'react-markdown';
import {DataTable} from 'react-widgets/components';
import {renderMultifieldString} from 'libs/salmon-utils';

export function renderModalField(block, rowData, styleSheet, styleName, styleLabel) {
  const className = styleSheet[styleName] ? css(styleSheet[styleName]) : '';

  return (
    <div className={className} key={block.property}>
      {block.label &&
        <div>
          <label className={css(styleSheet[styleLabel])}>{block.label}</label>
        </div>
      }
      {
        Array.isArray(rowData[block.property])
          ? (
            rowData[block.property].length > 0
              ? Object.values(rowData[block.property]).sort().join(', ')
              : block.default
          )
          : (
            rowData[block.property]
              ? rowData[block.property]
              : block.default
          )
      }
    </div>
  );
}

export const RedirectModal = (props) => {
  const {
    settings,
    styleSheet,
    handleExit,
    showRedirectModal,
  } = props;

  return (
    <Modal
      show={showRedirectModal}
      onHide={handleExit}
      aria-labelledby={'redirect-modal-title'}
      backdrop={'static'}
      backdropClassName={css(styleSheet.modalBackdrop)}
      dialogClassName={'pal-modal'}
      className={css(styleSheet.modal)}
    >
      <Modal.Header closeButton>
        <Modal.Title id={'redirect-modal-title'} className={'redirect-modal-title'}>
          {settings.page.redirectLinkModalTitle ? settings.page.redirectLinkModalTitle : 'This site is for US healthcare providers'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'redirect-modal-body'}>
        {settings.page.redirectLinkText}
      </Modal.Body>
      <Modal.Footer className={'pal-modal-footer'}>
        <Button className={css(styleSheet.modalSuccess)} onClick={handleExit}>Okay</Button>
      </Modal.Footer>
    </Modal>
  );
};

RedirectModal.protoTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  handleExit: PropTypes.func.isRequired,
  showRedirectModal: PropTypes.bool,
};

export const LoginModal = (props) => {
  const {
    settings,
    styleSheet,
    handleHide,
    handleRedirect,
    showLoginModal,
  } = props;

  return (
    <Modal
      show={showLoginModal}
      onHide={handleHide}
      aria-labelledby={'healthcare-modal-title'}
      backdrop={'static'}
      backdropClassName={css(styleSheet.modalBackdrop)}
      dialogClassName={'pal-modal'}
      className={css(styleSheet.modal)}
    >
      <Modal.Header closeButton>
        <Modal.Title id={'healthcare-modal-title'} className={'healthcare-modal-title'}>
          {settings.content.physicianVerificationPopup}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'healthcare-modal-body'}>
        {settings.content.physicianVerificationBanner}
      </Modal.Body>
      <Modal.Footer className={'pal-modal-footer'}>
        <Button className={css(styleSheet.modalReject)} onClick={handleRedirect}>{settings.content.physicianVerificationReject}</Button>
        <Button className={css(styleSheet.modalSuccess)} onClick={handleHide}>{settings.content.physicianVerificationAccept}</Button>
      </Modal.Footer>
    </Modal>
  );
};

LoginModal.protoTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  handleHide: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func.isRequired,
  showLoginModal: PropTypes.bool,
};

export const ExitModal = (props) => {
  const {
    settings,
    styleSheet,
    handleHide,
    handleOpenDocument,
    showExitModal,
  } = props;

  return (
    <Modal
      show={showExitModal}
      onHide={handleHide}
      aria-labelledby={'exit-modal-title'}
      backdrop={true}
      backdropClassName={css(styleSheet.modalBackdrop)}
      dialogClassName={'pal-modal'}
      className={css(styleSheet.modal)}
    >
      <Modal.Header closeButton>
        <Modal.Title id={'exit-modal-title'} className={'exit-modal-title'}>
          <ReactMarkdown escapeHtml={false} source={settings.content.interstitialHeader} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'exit-modal-body'}>
        {settings.content.interstitial}
      </Modal.Body>
      <Modal.Footer className={'pal-modal-footer'}>
        <Button className={css(styleSheet.interstitialModalReject)} onClick={handleHide}>{settings.content.interstitialReject}</Button>
        <Button className={css(styleSheet.interstitialModalSuccess)} onClick={handleOpenDocument}>{settings.content.interstitialAccept}</Button>
      </Modal.Footer>
    </Modal>
  );
};

ExitModal.protoTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  handleHide: PropTypes.func.isRequired,
  handleOpenDocument: PropTypes.func.isRequired,
  showExitModal: PropTypes.bool,
};

export const FeatureModal = (props) => {
  const {
    settings,
    styleSheet,
    handleHide,
    showFeatureModal,
  } = props;

  return (
    <Modal
      show={showFeatureModal}
      onHide={handleHide}
      aria-labelledby={'feature-modal-title'}
      backdrop={true}
      backdropClassName={css(styleSheet.modalBackdrop)}
      dialogClassName={'pal-modal'}
      className={css(styleSheet.modal)}
    >
      <Modal.Header closeButton>
        <Modal.Title id={'feature-modal-title'} className={'feature-modal-title'}>
          {settings.content.newFeatureBanner}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'feature-modal-body'}>
        <ReactMarkdown escapeHtml={false} source={settings.content.newFeaturePopup.markdown} />
      </Modal.Body>
      <Modal.Footer className={'pal-modal-footer'}>
        <Button className={css(styleSheet.modalSuccess)} onClick={handleHide}>Okay</Button>
      </Modal.Footer>
    </Modal>
  );
};

FeatureModal.protoTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  handleHide: PropTypes.func.isRequired,
  showFeatureModal: PropTypes.bool,
};

export const CoverageSummaryModal = (props) => {
  const {
    rowData,
    settings,
    styleSheet,
    handleHide,
    summaryColumns,
    showSummaryModal,
    handleDocumentClick,
  } = props;

  if (!rowData) {
    return null;
  }

  return (
    <Modal
      show={showSummaryModal}
      onHide={handleHide}
      aria-labelledby={'summary-modal-title'}
      backdrop={true}
      bsSize={'large'}
      backdropClassName={css(styleSheet.modalBackdrop)}
      dialogClassName={`pal-modal ${css(styleSheet.coverageSummaryModal)}`}
      className={css(styleSheet.modal)}
    >
      <Modal.Header closeButton>
        <Modal.Title id={'summary-modal-title'} className={'summary-modal-title'}>
          {rowData &&
            renderMultifieldString(settings.table.coverageSummaryModal.title, rowData)
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'summary-modal-body'}>
        {settings.table.coverageSummaryModal.layout === 'default' &&
          <div>
            <div style={{marginBottom: '15px'}}>
              <label className={css(styleSheet.coverageSummaryModalLabel)}>
                <span>{renderMultifieldString(settings.table.coverageSummaryModal.subtitle, rowData)}</span>
              </label>
            </div>
            {settings.table.coverageSummaryModal.body.map((block) => {
              return renderModalField(
                block,
                rowData,
                styleSheet,
                `coverageSummaryModalBody${block.property}`,
                'coverageSummaryModalLabel'
              );
            })}
          </div>
        }
        {
          settings.table.coverageSummaryModal.layout === 'table' &&
          <div>
            <span>{rowData.states.join(', ')}</span>
            <div className={css(styleSheet.dataTable)}>
              <DataTable
                rows={rowData.coverageSummaryFields}
                columns={summaryColumns}
                centerPagination={true}
              />
            </div>
          </div>
        }
      </Modal.Body>
      {
        settings.table.coverageSummaryModal.layout === 'table' &&
        <Modal.Footer className={'pal-modal-footer'}>
          <span>The above summary does not reflect the complete criteria as published by the payor.</span><br/>
          {(rowData && rowData.policyUrl) &&
            <span>Please refer to the <a  onClick={(e) => {
              handleHide();
              handleDocumentClick(e);
            }} href={rowData.policyUrl}>Coverage Document</a> link to view the entire criteria.</span>
          }
        </Modal.Footer>
      }
      {settings.table.coverageSummaryModal.disclaimer &&
        <Modal.Footer className={css(styleSheet.coverageSummaryModalFooter)}>
          <ReactMarkdown escapeHtml={false} source={settings.table.coverageSummaryModal.disclaimer} />
        </Modal.Footer>
      }
    </Modal>
  );
};

CoverageSummaryModal.protoTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  handleHide: PropTypes.func.isRequired,
  handleDocumentClick: PropTypes.func.isRequired,
  showSummaryModal: PropTypes.bool,
  summaryColumns: PropTypes.array,
  customLayout: PropTypes.bool.isRequired,
};

export const DisclaimerModal = (props) => {
  const {
    settings,
    styleSheet,
    handleHide,
    showDisclaimerModal,
  } = props;

  return (
    <Modal
      show={showDisclaimerModal}
      onHide={handleHide}
      aria-labelledby={'disclaimer-modal-title'}
      backdrop={true}
      backdropClassName={css(styleSheet.modalBackdrop)}
      dialogClassName={'pal-modal'}
      className={css(styleSheet.modal)}
    >
      <Modal.Header closeButton>
        <Modal.Title id={'disclaimer-modal-title'} className={'disclaimer-modal-title'}>
          {settings.table.disclaimerModal.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'disclaimer-modal-body'}>
        <ReactMarkdown escapeHtml={false} source={settings.table.disclaimerModal.content.markdown} />
      </Modal.Body>
      <Modal.Footer className={'pal-modal-footer'}>
        <Button className={css(styleSheet.modalSuccess)} onClick={handleHide}>
          {settings.table.disclaimerModal.disclaimerAccept}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DisclaimerModal.protoTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  handleHide: PropTypes.func.isRequired,
  showDisclaimerModal: PropTypes.bool,
};

export const ContactInstructionsModal = (props) => {
  const {
    rowData,
    settings,
    styleSheet,
    handleHide,
    showContactInstructionsModal,
  } = props;

  if (!rowData) {
    return null;
  }

  return (
    <Modal
      show={showContactInstructionsModal}
      onHide={handleHide}
      aria-labelledby={'contact-instructions-modal-title'}
      backdrop={true}
      bsSize={'large'}
      backdropClassName={css(styleSheet.modalBackdrop)}
      dialogClassName={`pal-modal ${css(styleSheet.contactInstructionsModal)}`}
      className={css(styleSheet.modal)}
    >
      <Modal.Header closeButton>
        <Modal.Title id={'contact-instructions-modal-title'} className={'contact-instructions-modal-title'}>
          {rowData &&
            settings.table.contactInstructionsModal.title
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'contact-instructions-modal-body'}>
        {
          <div>
            {rowData.contactinstructions &&
              <label className={css(styleSheet.contactInstructionsModalBodyinstructions)}>
                <span>{rowData.contactinstructions}</span>
              </label>
            }
            {settings.table.contactInstructionsModal.body.map((block) => {
              return renderModalField(
                block,
                rowData,
                styleSheet,
                `contactInstructionsModalBody${block.property}`,
                'contactInstructionsModalLabel'
              );
            })}
          </div>
        }
      </Modal.Body>
    </Modal>
  );
};

ContactInstructionsModal.protoTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  showContactInstructionsModal: PropTypes.bool,
};