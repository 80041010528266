/**
 * Action types
 */

export const CLAIMNOC_ERROR = 'CLAIMNOC_ERROR';
export const CLAIMNOC_REQUEST = 'CLAIMNOC_REQUEST';

export const GET_CLAIMNOC_DATA_SUCCESS  = 'GET_CLAIMNOC_DATA_SUCCESS';
export const SET_CLAIMNOC_SHOW_ALERT = 'SET_CLAIMNOC_SHOW_ALERT';

export const ERROR_ALERT = 'ERROR_ALERT';
export const NO_ROW_ALERT = 'NO_ROW_ALERT';


export function claimNOCRequest() {
  return {
    type: CLAIMNOC_REQUEST,
  };
}

export function claimNOCError(error, defaultError = null) {
  return {
    type: CLAIMNOC_ERROR,
    error: error.response,
    defaultError,
  };
}

export function getClaimNOCSuccess(data) {
  return {
    type: GET_CLAIMNOC_DATA_SUCCESS,
    payload: data,
  };
}

export function setClaimNOCShowAlert(data) {
  return {
    type: SET_CLAIMNOC_SHOW_ALERT,
    payload: data,
  };
}

export function getClaimNOCList(domain, defaultError) {
  return function (dispatch, getState, api) {
    dispatch(claimNOCRequest());
    return api.get(`/noc/${domain}`)
      .then(({data}) => {
        if (!data.data) {
          dispatch(claimNOCError({response: null}, defaultError));
        } else {
          dispatch(getClaimNOCSuccess({
            data: data.data,
            notes: data.notes,
          }));
        }
      })
      .catch((error) => dispatch(claimNOCError(error, defaultError)));
  };
}
