import React, {Component} from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  Button,
  Grid,
  Row,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
} from 'react-bootstrap';
import {css} from 'aphrodite';
import Bowser from 'bowser';

import {
  login,
  loginError,
  loginResetAlert,
  LOGIN_TYPE_USER_PASS,
  LOGIN_TYPE_NPI,
  LOGIN_TYPE_NPI_KEY,
  LOGIN_TYPE_TOKEN,
} from 'actions/login';

import {
  getError,
  getInProgress,
  getShowErrorAlert,
} from 'selectors/login';
import {getSettings} from 'selectors/settings';

import {getErrorField, createGlobalErrorObj} from 'react-widgets/libs';
import {ErrorAlert, Loader} from 'react-widgets/components';
import Isi from 'components/Isi';

import './styles.scss';

export class Login extends Component {
  constructor(props) {
    super(props);
    // Variables for Login & Password inputs
    this.state = {
      userError: null,
      userPassError: null,
    };

    const browser = Bowser.getParser(window.navigator.userAgent);
    this._isEdge = browser.getBrowserName() === 'Microsoft Edge';

    this._handleSubmitNPI = this._handleSubmitNPI.bind(this);
    this._handleSubmitNPIKey = this._handleSubmitNPIKey.bind(this);
    this._handleSubmitUserPass = this._handleSubmitUserPass.bind(this);
  }

  componentDidMount() {
    const {
      dispatchLogin,
      settings,
    } = this.props;
    if (settings.loginType === LOGIN_TYPE_TOKEN) {
      const loginToken = new URLSearchParams(window.location.search).get('token');
      if (loginToken !== null) {
        dispatchLogin(
          LOGIN_TYPE_TOKEN,
          loginToken,
          settings.name,
          settings.login.error.apiUnavailable,
          settings.login.error.userPassIncorrect
        );
      }
    }
  }

  _handleSubmitNPI(e) {
    e.preventDefault();

    const {
      dispatchLogin,
      dispatchLoginError,
      settings,
    } = this.props;

    const npi = e.target.querySelector('input[name="npiNumber"]').value.trim();
    if (npi.match(/^\d{10}$/)) {
      dispatchLogin(
        LOGIN_TYPE_NPI,
        npi,
        settings.login.error.apiUnavailable,
        settings.login.error.npiIncorrect
      );
    } else {
      dispatchLoginError({response: createGlobalErrorObj([settings.login.error.badNpi])});
    }

    this.forceUpdate();
  }

  /**
   * Verifies the npi credentials against a key.
   *
   * @param {tuple}  compareScheme - Dictates how to verify the key
   *                   against the npi number. Contains the
   *                   [compareType, value/regExpression]
   *                   possible compareTypes are "string" and  "regexOfNpiNumber".
   *                   example: ['regexOfNpiNumber', '[13579]']
   *                            ['string', 'password']
   * @param {string} npiNumber - The NPI Number.
   * @param {string} npiKey - The key (aka password).
   *
   * @return {boolean}
   **/
  _verifyNpiKey(compareScheme, npiNumber, npiKey) {
    let [scheme, schemeData] = compareScheme;
    if (scheme === 'string') {
      return (schemeData == npiKey);
    } else if (scheme === 'regexOfNpiNumber') {
      // Compare against a value derived from the npiNumber.
      const matchAgainst = npiNumber.match(new RegExp(schemeData, 'g'));
      if (matchAgainst === null) {
        return false;
      }
      // join() is called because the "password" is a concatenation of all the matches.
      return (matchAgainst.join('') === npiKey);
    } else {
      return false;
    }
  }

  _handleSubmitNPIKey(e) {
    e.preventDefault();

    const {
      dispatchLogin,
      dispatchLoginError,
      settings,
    } = this.props;

    const npi = e.target.querySelector('input[name="npiNumber"]').value.trim();
    const npiKey = e.target.querySelector('input[name="npiKey"]').value.trim();

    // compareType, value/regExpression.
    const compareSchemes = {
      1: ['regexOfNpiNumber', '\\d{4}$'], // Last 4 digits of NPI number.
      2: ['regexOfNpiNumber', '[13579]'], // Odd Digits of NPI number.
      3: ['string', settings.drugId] // productId.
    };

    let keyMatch = false;
    const scheme = compareSchemes[settings.login.npiKeyScheme];
    if (scheme) {
      keyMatch = this._verifyNpiKey(scheme, npi, npiKey);
    }

    if (npi.match(/^\d{10}$/) && keyMatch) {
      dispatchLogin(
        LOGIN_TYPE_NPI_KEY,
        npi,
        settings.login.error.apiUnavailable,
        settings.login.error.npiIncorrect
      );
    } else {
      dispatchLoginError({response: createGlobalErrorObj([settings.login.error.badNpi])});
    }

    this.forceUpdate();
  }

  _handleSubmitUserPass(e) {
    e.preventDefault();

    const {
      dispatchLogin,
      dispatchLoginError,
      settings,
    } = this.props;

    // Reset Error validation styles.
    let userError = null;
    let userPassError = null;

    const userName = e.target.querySelector('input[name="userLogin"]').value.trim();
    const userPassword = e.target.querySelector('input[name="userPassword"]').value.trim();

    const userRegex = /^[^\s]+$/; // Check username for no spaces.
    const userPasswordRegex = /^[^\s]{8,}$/; // Check for minimum 8 length without any spaces.

    if (!userName.match(userRegex)) {
      userError = 'error';
    }

    if (!userPassword.match(userPasswordRegex)) {
      userPassError = 'error';
    }

    if (!userError && !userPassError) {
      dispatchLogin(LOGIN_TYPE_USER_PASS, userName, userPassword, settings.name, settings.login.error.apiUnavailable, settings.login.error.userPassIncorrect);
    } else {
      dispatchLoginError({response: createGlobalErrorObj([settings.login.error.failed])});
    }

    this.setState({
      userError: userError,
      userPassError: userPassError,
    });

    this.forceUpdate();
  }

  /**
   * Return Login instruction element
   * @returns {JSX.Element}
   */
  loginInstruction() {
    const {
      settings,
      styleSheet,
    } = this.props;

    return (
      <ReactMarkdown className={css(styleSheet.loginInstruction)} escapeHtml={false} source={settings.login.instruction.markdown} />
    );
  }

  npiLogin() {
    const {
      dispatchLoginResetAlert,
      error,
      inProgress,
      settings,
      styleSheet,
      showErrorAlert,
    } = this.props;

    return (
      <div data-component={'Login'}>
        <Loader isFetching={inProgress} isFullscreen/>
        <Grid>
          <Row className={css(styleSheet.loginBackground)}>
            {settings.login.instruction && this.loginInstruction()}
            <ErrorAlert error={getErrorField('global', 'global', error)} alertVisible={showErrorAlert} handleDismissAlert={() => dispatchLoginResetAlert()}/>
            <Col sm={4} className={'col-sm-offset-4'}>
              <Form onSubmit={this._handleSubmitNPI}>
                <FormGroup validationState={error ? 'error' : null}>
                  <ControlLabel>NPI Number</ControlLabel>
                  <FormControl
                    name={'npiNumber'}
                    type={'text'}
                    required={'required'}
                    placeholder={'NPI Number'}
                    autoComplete={this._isEdge ? 'off' : 'on'}
                  />
                </FormGroup>
                <Button type={'submit'} className={css(styleSheet.loginButton)} block>{settings.login.buttonLabel || 'Login'}</Button>
              </Form>
            </Col>
          </Row>
          <div className={css(styleSheet.loginContent)}>
            {settings.login.content.markdown && <ReactMarkdown escapeHtml={false} source={settings.login.content.markdown} />
            }
          </div>
          <Isi settings={settings.isi} styleSheet={styleSheet} />
          {settings.content.bottomSection &&
            <Row><div className={css(styleSheet.bottomSection)}>
              <ReactMarkdown escapeHtml={false} source={settings.content.bottomSection.markdown} />
            </div></Row>
          }
        </Grid>
      </div>
    );
  }

  npiKeyLogin() {
    const {
      dispatchLoginResetAlert,
      error,
      inProgress,
      settings,
      styleSheet,
      showErrorAlert,
    } = this.props;

    return (
      <div data-component={'Login'}>
        <Loader isFetching={inProgress} isFullscreen/>
        <Grid>
          <Row className={css(styleSheet.loginBackground)}>
            {settings.login.instruction && this.loginInstruction()}
            <ErrorAlert error={getErrorField('global', 'global', error)} alertVisible={showErrorAlert} handleDismissAlert={() => dispatchLoginResetAlert()}/>
            <Col sm={4} className={'col-sm-offset-4'}>
              <Form onSubmit={this._handleSubmitNPIKey}>
                <FormGroup validationState={error ? 'error' : null}>
                  <ControlLabel>{settings.login.loginFieldLabel}</ControlLabel>
                  <FormControl
                    name={'npiNumber'}
                    type={'text'}
                    required={'required'}
                    placeholder={settings.login.loginFieldPlaceholder}
                    autoComplete={this._isEdge ? 'off' : 'on'}
                  />
                  <br/>
                  <ControlLabel>{settings.login.passwordFieldLabel}</ControlLabel>
                  <FormControl
                    name={'npiKey'}
                    type={'text'}
                    required={'required'}
                    placeholder={settings.login.passwordFieldPlaceholder}
                    autoComplete={this._isEdge ? 'off' : 'on'}
                  />
                </FormGroup>
                <Button type={'submit'} className={css(styleSheet.loginButton)} block>{settings.login.buttonLabel || 'Login'}</Button>
              </Form>
            </Col>
          </Row>
          <div className={css(styleSheet.loginContent)}>
            {settings.login.content.markdown && <ReactMarkdown escapeHtml={false} source={settings.login.content.markdown} />
            }
          </div>
          <Isi settings={settings.isi} styleSheet={styleSheet} />
          {settings.content.bottomSection &&
            <div className={css(styleSheet.bottomSection)}>
              <ReactMarkdown escapeHtml={false} source={settings.content.bottomSection.markdown} />
            </div>
          }
        </Grid>
      </div>
    );
  }

  userPassLogin() {
    const {
      dispatchLoginResetAlert,
      error,
      inProgress,
      settings,
      styleSheet,
      showErrorAlert,
    } = this.props;

    return (
      <div data-component={'Login'}>
        <Loader isFetching={inProgress} isFullscreen/>
        <Grid>
          <Row className={css(styleSheet.loginBackground)}>
            {this.loginInstruction()}
            <ErrorAlert error={getErrorField('global', 'global', error)} alertVisible={showErrorAlert} handleDismissAlert={() => dispatchLoginResetAlert()}/>
            <Col sm={4} className={'col-sm-offset-4'}>
              <Form onSubmit={this._handleSubmitUserPass}>
                <FormGroup validationState={this.state.userError}>
                  <ControlLabel>Username</ControlLabel>
                  <FormControl
                    name={'userLogin'}
                    type={'text'}
                    required={'required'}
                    placeholder={'Email Address or User Name'}
                    autoComplete={this._isEdge ? 'off' : 'on'}
                  />
                </FormGroup>
                <FormGroup validationState={this.state.userPassError}>
                  <ControlLabel>Password</ControlLabel>
                  <FormControl
                    name={'userPassword'}
                    type={'password'}
                    required={'required'}
                    placeholder={'Password'}
                    minLength={8}
                  />
                </FormGroup>
                <Button type={'submit'} className={css(styleSheet.loginButton)} block>Login</Button>
              </Form>
            </Col>
          </Row>
          <div className={css(styleSheet.loginContent)}>
            {settings.login.content.markdown && <ReactMarkdown escapeHtml={false} source={settings.login.content.markdown} />
            }
          </div>
          <Isi settings={settings.isi} styleSheet={styleSheet} />
          {settings.content.bottomSection &&
            <div className={css(styleSheet.bottomSection)}>
              <ReactMarkdown escapeHtml={false} source={settings.content.bottomSection.markdown} />
            </div>
          }
        </Grid>
      </div>
    );
  }

  render() {
    const {
      settings,
    } = this.props;

    if (settings.loginType && settings.loginType === LOGIN_TYPE_USER_PASS) {
      return this.userPassLogin();
    }

    if (settings.loginType && settings.loginType === LOGIN_TYPE_TOKEN) {
      // LOGIN_TYPE_TOKEN login code is actually in ComponentDidMount(),
      // inlining the call to dispatch() here would cause an infinite loop.
      // userPassLogin is the fallback Login if token authentication fails.
      return this.userPassLogin();
    }

    if (settings.loginType && settings.loginType === LOGIN_TYPE_NPI_KEY) {
      return this.npiKeyLogin();
    }

    // Return the default NPI login mechanism.
    return this.npiLogin();
  }
}


Login.propTypes = {
  dispatchLogin: PropTypes.func.isRequired,
  dispatchLoginError: PropTypes.func.isRequired,
  dispatchLoginResetAlert: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  showErrorAlert: PropTypes.bool,
  inProgress: PropTypes.bool,
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  return {
    error: getError(state),
    inProgress: getInProgress(state),
    settings: getSettings(state),
    showErrorAlert: getShowErrorAlert(state),
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    dispatchLogin: (...args) => {
      dispatch(login(...args));
    },
    dispatchLoginError: (...args) => {
      dispatch(loginError(...args));
    },
    dispatchLoginResetAlert: (...args) => {
      dispatch(loginResetAlert(...args));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
