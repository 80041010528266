import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Grid, Row, Col} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import {css} from 'aphrodite';

import Isi from 'components/Isi';

export class Faq extends Component {
  render() {
    const {settings, styleSheet} = this.props;

    let linkRenderer = (props) => {
      return (<a
        href={props.href}
        className={css(styleSheet.faqLink)}
      >
        {props.children}
      </a>);
    };
    if (settings.faq.content.linkNewTab) {
      linkRenderer = (props) => {
        // Do not open links to elements in a new tab.
        let target = props.href.charAt(0) === '#' ? null : '_blank';
        return (<a
          rel="noopener noreferrer"
          href={props.href}
          target={target}
          className={css(styleSheet.faqLink)}
        >
          {props.children}
        </a>);
      };
    }

    const tableRenderer = (props) => {
      return <table className={'table table-striped'} >{props.children}</table>;
    };

    return (
      <div data-component={'Faq'}>
        <Grid>
          <Row>
            {
              settings.faq.content.preface &&
              <div className={css(styleSheet.faqPreface)}>
                <ReactMarkdown escapeHtml={false} source={settings.faq.content.preface}/>
              </div>
            }
            <Col className={css(styleSheet.faq)} sm={12}>
              <ReactMarkdown
                escapeHtml={false}
                source={settings.faq.content.markdown}
                renderers={{
                  link: linkRenderer,
                  table: tableRenderer,
                }}
              />
            </Col>
          </Row>
          <Isi settings={settings.isi} styleSheet={styleSheet}/>
          {settings.content.bottomSection &&
            <Row><div className={css(styleSheet.bottomSection)}>
              <ReactMarkdown escapeHtml={false} source={settings.content.bottomSection.markdown} />
            </div></Row>
          }
        </Grid>
      </div>
    );
  }
}

Faq.propTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  const {
    settings,
  } = state;

  return {
    settings: settings.get('settings').toJS(),
  };
};

export default connect(
  mapStateToProps
)(Faq);
