/**
 * This file is called salmon-utils instead of utils to prevent naming conflicts.
 **/

import {
  find,
} from 'lodash';


export const coveragePositionTemplate = {
  coveragepositionid: null,
  urlid: null,
  drugid: null,
  payerid: null,
  payer: null,
  pbmid: null,
  pbm: null,
  product: null,
  policyUrl: null,
  priorAuthorizationUrl: null,
  priorAuthorizationRequired: null,
  priorAuthorizationText: null,
  priorAuthorizationCriteriaUrl: null,
  priorAuthorizationFormUrl: null,
  priorAuthorizationListUrl: null,
  priorAuthorizationRationaleUrl: null,
  initialdiagnosis: null,
  continueddiagnosis: null,
  initialquantitylimit: null,
  continuedquantitylimit: null,
  initialagerequirement: null,
  continuedagerequirement: null,
  initialprescriberrequirements: null,
  continuedprescriberrequirements: null,
  initialreauthorizationduration: null,
  continuedreauthorizationduration: null,
  stepEdit: [],
  stepEditText: null,
  covered: null,
  prescriberRequirementsText: null,
  monitoringRequirement: null,
  criteria: null,
  criteriamarkdown: null,
  pbpaPhone: null,
  pbpaFax: null,
  indicationIds: [],
  presenceNodes: [],
  coveredMedicalCodes: [],
  contacts: [],
  contactinstructions: null,
  peertopeer: null,
  expeditedpa: null,
  verballyConfirmed: false,
  lastReviewDate: null,
  benefitType: null,
  salmonDocuments: []
};

/**
 * Returns true if the passed in array contains at least all the states
 * in the USA.
 * Returns false otherwise
 *
 * @param {array} states - Array containing the 2 letter abbrevations for US states.
 *
 * @return {boolean}
 **/
export function containsAllStates(states) {
  const ALL_STATES = [
    'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE',
    'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY',
    'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT',
    'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH',
    'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
    'VA', 'VT', 'WA', 'WI', 'WV', 'WY',
  ];

  const upperCaseStates = states.map((x) => x.toUpperCase());

  return ALL_STATES.every((x) => upperCaseStates.includes(x));
}

/**
 * Returns the Data ordered by "property".
 *
 * @param {object} data - object containing the specified `property`.
 * @param {array}  ordering - array containing the ordering to use.
 *
 * @return {array}
 **/
export function orderData(data, property, ordering) {
  // Had to do it this way to expose ordering to the comparison function.
  function _compareData(a, b) {
    const aIndex = ordering.indexOf(a[property]);
    const bIndex = ordering.indexOf(b[property]);
    // If a doesn't have an ordering and b does, we want b to be infront of a.
    if (aIndex === -1 && bIndex !== -1) {
      return 1; // Positive means to put B infront of A.
    }
    // If b doesn't have an ordering and a does, we want a to be infront of b.
    if (aIndex !== -1 && bIndex === -1) {
      return -1; // Negative means to put A infront of B.
    }
    return aIndex - bIndex;
  }

  return data.sort(_compareData);
}

/**
 * Adds an fontawesome font specification to the contact object.
 * defaults to fas-phone.
 *
 * @param {array}  contacts    - object containing a `type` property.
 * @param {Object} iconTypes   - object containing a fontawesome specified font mapped to the `type`.
 * @param {Object} defaultIcon - default fontawesome icon to use.
 *
 * @return {array}
 **/
export function addContactIcons(contacts, iconTypes, defaultIcon = {prefix: 'fas', iconName: 'phone', title: '', tooltipAlign: 'bottom', default: 'N/A'}) {
  contacts.forEach((el) => {
    const useIcon = iconTypes[el.type] ? iconTypes[el.type] : defaultIcon;
    el.iconPrefix = useIcon.prefix;
    el.iconName =  useIcon.iconName;
    el.title = useIcon.title;
    el.tooltipAlign = useIcon.tooltipAlign;
    el.default = useIcon.default;
  });

  return contacts;
}

/**
 * Exclude or include a state from/to an array of selectedStates.
 *
 * @param {array}  selectedStates - array containing state objects (could be an empty array).
 *                                  ie [{id: 'ND', label: 'North Dakota'},...]
 * @param {string} stateID        - 2 letter state abbreviation.
 * @param {bool}   include        - If TRUE, add the state to selectedStates. If FALSE remove the state from selectedStates.
 * @param {array}  allStates      - array containing state objects.
 *
 * @return {array}
 **/
export function filterForMapStates(selectedStates, stateID, include, allStates) {
  const stateElement = find(allStates, {id: stateID});
  let currentSelectedStates = [...selectedStates];

  if (include) {
    if (!find(currentSelectedStates, stateElement)) {
      currentSelectedStates.push(stateElement);
    }
  } else {
    currentSelectedStates = currentSelectedStates.filter(
      (state) => state.id !== stateID
    );
  }

  return currentSelectedStates;
}

/**
 * Returns string with the words enclosed in [key] replaced.
 * if allStates parameter is provided, replace the states with the string parameter provided.
 * Has a side effect of sorting all the states.
 *
 * @param {string} string    - string with keys to replace.
 *                             ie '[[payer]] - [[planType]] - [[states]]'
 * @param {object} rowData   - Object contains properties corresponding to the keys to replace.
 * @param {string} allStates - (optional) string to replace states with if ALL US states are present.
 *
 * @return {string}
 **/
export function renderMultifieldString(string, rowData, allStates = '') {
  return string.replace(/\[\[(.*?)\]\]/g, (junk, match) => {
    let foundValue = rowData[match] ? rowData[match] : '';

    if (foundValue) {
      if (allStates && match === 'states') {
        if (containsAllStates(rowData.states)) {
          return allStates;
        }
      }
      if (Array.isArray(foundValue)) {
        return foundValue.sort().join(', ');
      }
    }

    return foundValue;
  });
}

/**
 * Takes the medical coverage positions and transforms it to a common format for PAL consumption.
 * Returns a new object containing all the required properties.
 *
 * @param {object} data   - Object containing medical coverage positions.
 *
 * @return {object}
 **/
export function formatMedCoveragePosition(data) {

  return {
    ...coveragePositionTemplate,
    ...data,
    benefitType: 'Medical',
  };
}


/**
 * Takes the pharmacy coverage positions and transforms it to a common format for PAL consumption.
 * Returns a new object containing all the required properties.
 *
 * @param {object} data   - Object containing medical coverage positions.
 *
 * @return {object}
 **/
export const formatRxCoveragePosition = (data) => {
  return {
    ...coveragePositionTemplate,
    coveragepositionid: `rx-${data.coveragepositionid}`,
    drugid: data.product !== undefined ? data.product.id : coveragePositionTemplate.drugid,
    benefitType: 'Pharmacy',
    payer: data.pbmClient.name,
    payerid: data.pbmClient.id,
    pbm: data.pbm.name,
    pbmid: data.pbm.id,
    contacts: data.contacts !== undefined ? data.contacts : coveragePositionTemplate.contacts,
    product: data.product !== undefined ? data.product : coveragePositionTemplate.product,
    covered: data.covered !== undefined ? data.covered : coveragePositionTemplate.covered,
    criteria: data.criteria !== undefined ? data.criteria : coveragePositionTemplate.criteria,
    lastReviewDate: data.lastReviewDate !== undefined ? data.lastReviewDate : coveragePositionTemplate.lastReviewDate,
    presenceNodes: createRxPresenceNodes(data.states, data.planType.name, data.planType.lob.name),
    policyUrl: data.policyUrl !== undefined ? data.policyUrl : coveragePositionTemplate.policyUrl,
    priorAuthorizationRequired: data.priorAuth,
    priorAuthorizationUrl: data.priorAuthorizationUrl !== undefined ? data.priorAuthorizationUrl : coveragePositionTemplate.priorAuthorizationUrl,
    salmonDocuments: data.salmonDocuments !== undefined ? data.salmonDocuments : coveragePositionTemplate.salmonDocuments,
    stepEditText: data.stepEditText !== undefined ? data.stepEditText : coveragePositionTemplate.stepEditText,
    indicationIds: data.indicationIds !== undefined && data.indicationIds !== null  ? data.indicationIds : coveragePositionTemplate.indicationIds,
  };
}

/**
 * Returns formatted RxCoveragePositions
 * @param rawRxData
 * @returns {*[]}
 */
export const formatRxCoveragePositions = (rawRxData) => {
  //format rx data without indications
  const formattedRxDataWithoutIndications = rawRxData
    .filter((rxcp) => {
      return !rxcp.indications;
    })
    .map(formatRxCoveragePosition);

  //Flat mapping additional rx position rows with indication data
  const formattedRxDataWithIndications = rawRxData
    .filter((rxcp) => {
      return rxcp.indications && rxcp.indications.length > 0;
    })
    .flatMap((rxcp) => {
      return rxcp.indications.map((indication) => {
        return {
          ...rxcp,
          coveragepositionid: `${rxcp.coveragepositionid}-${indication.indicationid}`,
          criteria: indication.criteria,
          indicationIds: [indication.indicationid],
        }
      })
    })
    .map(formatRxCoveragePosition);

  return [
    ...formattedRxDataWithoutIndications,
    ...formattedRxDataWithIndications,
  ];
};

/**
 * Validates properties of an object against a dictionary of key/value pairs.
 * If keyValues is empty, true is returned.
 *
 * @param {object} obj       - object with the properties to inspect.
 * @param {object} keyValues - dictionary containing the key/value pairs
 *
 * @return boolean
 */
export function validateObjectProperties(obj, keyValues) {
  let passingCriteria = [];
  for (const [key, value] of Object.entries(keyValues)) {
    passingCriteria.push((obj[key] === value));
  }

  return passingCriteria.every((x) => x === true);
}

/**
 * Creates PresencesNodes for Rx coverage positions.
 *
 * Special case:
 * When user filters for Commercial, it should show commercial.
 * In All other cases, the show the granular plan type.
 *
 * @param {array} states    - array of state objects.
 * @param {string} planType - the granular plan type, this is what is the plantype filter filters for.
 * @param {string} lob      - line of business (can be loosely conceptualized as the planType "superset").
 *                            This is what is displayed in the UI.
 *
 * @return {array of objects}
 */
function createRxPresenceNodes(states, planType, lob) {
  let presenceNodes = [];
  for (const s of states) {
    presenceNodes.push({
      stateAbbr: s.id,
      originalPlanType: lob === 'Commercial' ? lob : planType,
      planType: lob === 'Commercial' ? lob : planType,
    });
  }

  return presenceNodes;
}
