// Action types.
export const LOGIN_ERROR             = 'LOGIN_ERROR';
export const LOGIN_REQUEST           = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST          = 'LOGOUT_REQUEST';
export const LOGIN_SUCCESS           = 'LOGIN_SUCCESS';
export const LOGIN_RESET_ALERT       = 'LOGIN_RESET_ALERT';

// Login Types.
export const LOGIN_TYPE_USER_PASS    = 'userPass';
export const LOGIN_TYPE_NPI          = 'npi';
export const LOGIN_TYPE_NPI_KEY      = 'npiKey';
export const LOGIN_TYPE_TOKEN        = 'token';

export function loginRequest() {
  return {
    type: LOGIN_REQUEST,
  };
}

export function loginResetAlert() {
  return {
    type: LOGIN_RESET_ALERT,
  };
}

export function loginError(error, defaultError) {
  return {
    type: LOGIN_ERROR,
    error: error.response,
    defaultError,
  };
}

export function login(loginType, ...args) {
  return function (dispatch, getState, api) {
    dispatch(loginRequest());
    if (loginType === LOGIN_TYPE_USER_PASS) {
      let [userName, userPassword, domain, defaultError, loginFailError] = args;
      return api.post('/login', {
        userName: userName,
        userPassword: userPassword,
        domain: domain,
      })
        .then(({data}) => dispatch(loginSuccess(data, loginFailError)))
        .catch((error) => dispatch(loginError(error, defaultError)));

    } else if ([LOGIN_TYPE_NPI, LOGIN_TYPE_NPI_KEY].includes(loginType)) {
      let [id, defaultError, loginFailError] = args;

      return api.get(`/login/npi/${id}`)
        .then(({data}) => dispatch(loginSuccess(data, loginFailError)))
        .catch((error) => dispatch(loginError(error, defaultError)));

    } else if (loginType === LOGIN_TYPE_TOKEN) {
      let [loginToken, loginDomain, defaultError, loginFailError] = args;
      return api.post('/login', {
        token: loginToken,
        domain: loginDomain,
      })
        .then(({data}) => dispatch(loginSuccess(data, loginFailError)))
        .catch((error) => dispatch(loginError(error, defaultError)));
    } else { // No Login Type provided.
      dispatch(loginError('', 'Unsupported Login error.'));
    }
  };
}

export function logout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function loginSuccess(data, loginFailError) {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
    loginFailError,
  };
}
