import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Row} from 'react-bootstrap';
import {css} from 'aphrodite';

import {nodeId} from 'react-widgets/libs';

import './styles.scss';

const StateCoverageProfileDocumentDownload = (props) => {
  const {settings, styleSheet, stateCoverageProfiles} = props;

  // Style SCP states which don't actually link anywhere (e.g., if no SCPs are found)
  const linkRenderer = (linkProps) => {
    if (!linkProps.href) {
      return (
        <span key={nodeId()} className={css(styleSheet.stateCoverageProfileDocumentText)}>
          {linkProps.text}
        </span>
      );
    }

    return (
      <a href={linkProps.href} className={css(styleSheet.stateCoverageProfileDocumentLink)} key={nodeId()} target={'_blank'}>
        {linkProps.text}
      </a>
    );
  };

  if (!stateCoverageProfiles.length) {
    return null;
  }

  return (
    <Grid data-component={'StateCoverageProfileDocumentDownload'}>
      <Row className={css(styleSheet.stateCoverageProfileDocumentBox)}>
        <p className={css(styleSheet.stateCoverageProfileDocumentLink)}>
          <span className={css(styleSheet.stateCoverageProfileDocumentText)}>{settings.stateCoverageProfiles.content.label} </span>
          {
            stateCoverageProfiles.map((profile, index) => linkRenderer({
              index,
              text: profile.state,
              href: profile.document,
            })).reduce((prev, curr) => [prev, ', ', curr])
          }
        </p>

        <p>
          <small className={css(styleSheet.stateCoverageProfileDocumentLink)}>
            {settings.stateCoverageProfiles.content.disclaimer}
          </small>
        </p>
      </Row>
    </Grid>
  );
};

StateCoverageProfileDocumentDownload.propTypes = {
  stateCoverageProfiles: PropTypes.array.isRequired,
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
};

export default StateCoverageProfileDocumentDownload;
