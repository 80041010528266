import React, {Component, createElement} from 'react';
import {Row, Col} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import BoxedIsi from 'components/BoxedIsi';
import {css} from 'aphrodite';

export default class Isi extends Component {
  render() {
    const {settings, styleSheet} = this.props;

    let linkRenderer = (props) => {
      return (<a
        href={props.href}
        className={css(styleSheet.isiContentLink)}
      >
        {props.children}
      </a>);
    };
    if (settings.content.linkNewTab) {
      linkRenderer = (props) => {
        return (<a
          rel="noopener noreferrer"
          href={props.href}
          target="_blank"
          className={css(styleSheet.isiContentLink)}
        >
          {props.children}
        </a>);
      };
    }

    const boldRenderer = (props) => {
      return (<span className={css(styleSheet.isiContentBold)}>
        {props.children}
      </span>);
    };

    const headingRenderer = (props) => {
      return (settings.anchorTaggedHeaderText.length && typeof(props.children[0]) === 'string' && props.children[0].toLowerCase().includes(settings.anchorTaggedHeaderText)) ?
        createElement(`h${props.level}`, {id: 'isi'}, props.children) :
        createElement(`h${props.level}`, {}, props.children);
    };

    // Any text inside a \t tag will be enclosed in a box.
    const codeBoxRenderer = (props) => {
      return <BoxedIsi enabled={true} settings={settings} source={props.value} styleSheet={styleSheet} />;
    };

    return (
      <Row>
        <Col {...(!settings.anchorTaggedHeaderText.length && {id: 'isi'})} className={css(styleSheet.isi)} sm={12}>
          <ReactMarkdown escapeHtml={false} source={settings.content.markdown} renderers={{link: linkRenderer, strong: boldRenderer, heading: headingRenderer, code: codeBoxRenderer}} />
        </Col>
      </Row>
    );
  }
}
