import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import ReactMarkdown from 'react-markdown';

export const Banner = (props) => {
  PropTypes.checkPropTypes(bannerProtoTypes, props, 'prop', 'Banner');
  const {
    effectDateString,
    effectStatement,
    footerParagraphs,
    table,
  } = props;

  /**
   * Returns table row config with key
   * @param columnKey
   * @returns {*}
   */
  const findColumnConfig = (columnConfigs, columnKey) => {
    return columnConfigs.find((column) => column.key === columnKey);
  };

  return (
    <div data-component={'Banner'}>
      {/*first div is the header with title and description*/}
      <div className={'header'}>
        <b>{effectDateString}</b>
        <div>
          <ReactMarkdown escapeHtml={false} source={effectStatement}/>
        </div>
      </div>
      {/*second div is the table of the jcode detail*/}
      {table && <div>
        <div style={table.title.style}>{table.title.content}</div>
        <table style={table.style.table}>
          <thead><tr style={table.style.thead}>
            {table.columns.map((column, theadColumnKey) => (
              <td key={theadColumnKey} style={column.header.style}>{column.header.label}</td>
            ))}
          </tr></thead>
          <tbody>
            {table.data.map((row, rowIndex) => (
              <tr key={rowIndex} style={table.style.tbody}>
                {/* find the column style with the column key */}
                {Object.keys(row).map((columnKey, columnIndex) => (
                  <td
                    className={columnKey}
                    key={columnKey + columnIndex}
                    style={findColumnConfig(table.columns, columnKey).style}
                  >
                    {row[columnKey]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>}
      {/*third div is the footer*/}
      {footerParagraphs && <div className={'footer'}>
        {footerParagraphs && footerParagraphs.map((paragraph, rowKey) => (
          <div key={rowKey} style={paragraph.style}>
            <ReactMarkdown escapeHtml={false} source={paragraph.content} />
          </div>
        ))}
      </div>}
    </div>
  );
};

const bannerProtoTypes = {
  effectDateString: PropTypes.string.isRequired,
  effectStatement: PropTypes.string.isRequired,
  footerParagraphs: PropTypes.array,
  table: PropTypes.object,
};
