import celltherapy360Logo from 'assets/CT360-LOGO-RBG_CT360.png';
import bmsLogo from 'assets/bms_logo_rgb_pos_150.png';
import pluvictoLogo from 'assets/pluvicto_logo.png';
import novartisLogo from 'assets/Novartis_Logo_RGB.png';
import cizplamLogo from 'assets/Cizplam_logo.png';
import ventixalLogo from 'assets/ventixal_logo.png';
import uroliftLogo from 'assets/UROLIFT_2c_GoldO_BKTAG.png';
import teleflexLogo from 'assets/Teleflex_Logo_BK.png';
import saphneloLogoRtm from 'assets/saphnelo-dosage-rtm-a360-logo.png';
import imjudoLogo from 'assets/imjudo-a360.png';
import aquablationLogo from 'assets/Aquablation_Logo_RGB_Lockup_150.png';
import proceptBioroboticsLogo from 'assets/PROCEPT_Logo_Solid_RGB_150.png';
import triptodurLogo from 'assets/triptodur-logo.png';
import azurityLogo from 'assets/azurity-logo-white.svg';
import imfinziImjudoAccess360 from 'assets/imfinzi-imjudo-access360.png';
import watchmanLogo from 'assets/PAL_Image_2158x477-600dpi.png';
import bostonScientificLogo from 'assets/BSC_wtag_541blue.png';
import fasenraLogo from 'assets/FASENRA_R_logo_exUS_RGB_Icon_Left.jpg';
import danatechLogo from 'assets/danatech_Logo_2022.png'
import azLogoWhite from 'assets/astraZeneca.png';
import azLogo from 'assets/AZ-logo.png';
import silkRoadLogo from 'assets/SilkRoadMedical_Logo_KO_Orange_RGB.png';
import tcarLogo from 'assets/SRM_Less Invasive Standard Logo 5PF_2nd Jan 2024.png';
import wainuaLogo from 'assets/WAINUA_US_TM_Prom_RGB_FC_Pos.png';
import leqembiLogo from 'assets/logo-leqembi.png';
import eisaiLogo from 'assets/eisai.png';
import ilevroLogo from 'assets/ILV logo_white.png';
import harrowLogo from 'assets/Harrow_logo_Rev wTag wNewTM (1).png';
import spravatoLogo from 'assets/Spravato_logo_r_c3_color_rgb.png';
import jjLogo from 'assets/JJ_Logo_SingleLine_Red_RGB.png';

const logoAssets = {
  // Abecma.
  'celltherapy360-logo.png': celltherapy360Logo,
  'bms-logo.png': bmsLogo,
  // Pluvicto.
  'pluvicto_logo.png': pluvictoLogo,
  'novartis_logo.png': novartisLogo,
  // Cizplam Sales Demo site.
  'cizplam-logo.png' : cizplamLogo,
  // Ventixal Sales Demo site.
  'ventixal-logo.png' : ventixalLogo,
  // Urolift.
  'urolift-logo.png': uroliftLogo,
  'teleflex-logo.png': teleflexLogo,
  // Astrazeneca Saphnelo NOC.
  'saphnelo-logo-rtm.png': saphneloLogoRtm,
  // Astrazeneca Imjudo NOC.
  'imjudo-logo.png': imjudoLogo,
  // Multiple AZ sites - Note: ALL AZ sites share this image.
  'az-logo.png': azLogo,
  'az-logo-white.png': azLogoWhite,
  // Danatech.
  'danatech-logo.png': danatechLogo,
  // Fasenra.
  'fasenra-logo.png': fasenraLogo,
  // Boston Scientific.
  'bostonscientific-logo.png': bostonScientificLogo,
  'watchman-logo.png': watchmanLogo,
  // Aquablation
  'aquablation-logo.png': aquablationLogo,
  'procept-biorobotics-logo.png': proceptBioroboticsLogo,
  // Triptodur
  'triptodur-logo.png': triptodurLogo,
  'azurity-logo.png': azurityLogo,
  //Imfinzi + Imjudo
  'imfinzi-imjudo-access360.png': imfinziImjudoAccess360,
  //enroute
  'silk-road-logo.png': silkRoadLogo,
  'tcar-logo.png': tcarLogo,
  //wainua
  'wainua-logo.png': wainuaLogo,
  //leqembi
  'leqembi-logo.png': leqembiLogo,
  'eisai-logo.png': eisaiLogo,
  //harrow PAL
  'ilevro-logo.png': ilevroLogo,
  'harrow-logo.png': harrowLogo,
  //spravato
  'spravato-logo.png': spravatoLogo,
  'jj-logo.png': jjLogo,
};

export default logoAssets;
