import React, {Component, createElement} from 'react';
import PropTypes from 'prop-types';
import {Row, Grid} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import {css} from 'aphrodite';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {faChevronCircleUp, faChevronCircleDown, faChevronUp, faChevronDown} from '@fortawesome/fontawesome-pro-regular';

class BoxedIsi extends Component {
  constructor(props) {
    super(props);

    let boxedIsiExpanded = props.settings.boxedIsi.initializeExpanded;

    if (localStorage.getItem('boxedIsi-expanded') !== null) {
      boxedIsiExpanded = localStorage.getItem('boxedIsi-expanded') === 'true';
    }

    this.state = {
      expanded: boxedIsiExpanded,
      iconHeight: null,
    };

    this._toggleExpandBox = this._toggleExpandBox.bind(this);
  }

  _toggleExpandBox() {
    localStorage.setItem('boxedIsi-expanded', !this.state.expanded);
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  getChildrenHeight(node) {
    if (node && this.state.iconHeight === null) {
      this.setState({
        iconHeight: node.offsetHeight,
      });
    }
  }

  render() {
    const {settings, styleSheet, source, topRightLink, fixed, expandable, enabled} = this.props;
    const {expanded, iconHeight} = this.state;

    if (!enabled) {
      return null;
    }

    let linkRenderer = (props) => {
      return (<a
        href={props.href}
        className={css(styleSheet.isiContentLink)}
      >
        {props.children}
      </a>);
    };
    if (settings.content.linkNewTab) {
      linkRenderer = (props) => {
        return (<a
          rel={'noopener noreferrer'}
          href={props.href}
          target={'_blank'}
          className={css(styleSheet.isiContentLink)}
        >
          {props.children}
        </a>);
      };
    }

    const boldRenderer = (props) => {
      return (<span className={css(styleSheet.isiContentBold)}>
        {props.children}
      </span>);
    };

    const boxedIsiSpecialRenderer = (props) => {
      return createElement(`h${props.level}`, {className: css(styleSheet.boxedIsiSpecialRenderer)}, props.children);
    };

    // vertically align two inline-block elements with one of them having dynamic height
    let topRightLinkHeight = iconHeight ? `${iconHeight}px` : '0';

    let topRightLinkElements = [];
    if (expandable) {
      let expandSymbol = settings.boxedIsi.expandToggleIconCircle ? faChevronCircleUp : faChevronUp;
      let collapseSymbol = settings.boxedIsi.expandToggleIconCircle ? faChevronCircleDown : faChevronDown;
      let expandIcon = <FontAwesomeIcon className={css(styleSheet.topRightExpandToggle)} size={settings.boxedIsi.expandToggleIconSize} icon={expanded ? collapseSymbol : expandSymbol} />;

      topRightLinkElements.push(<a
        ref={(node) => this.getChildrenHeight(node)}
        key={'expandLink'}
        style={{display: 'inline-block', lineHeight: topRightLinkHeight}}
        className={'pull-right'}
        onClick={this._toggleExpandBox}>{expandIcon}
      </a>);
    }
    if (topRightLink) {
      topRightLinkElements.push(
        <a key={'topLink'} style={{display: 'inline-block', lineHeight: topRightLinkHeight}} className={'pull-right'} href={topRightLink.href}>
          <span className={css(styleSheet.topRightLink)}>{topRightLink.name}</span>
        </a>
      );
    }

    return (
      fixed ?
        <Row className={expanded ? css(styleSheet.boxedIsi, styleSheet.fixedIsi, styleSheet.isiExpanded) : css(styleSheet.boxedIsi, styleSheet.fixedIsi, styleSheet.isiShrunk)}>
          <Grid>
            <span>
              {topRightLinkElements}
            </span>
            <ReactMarkdown escapeHtml={false} source={source} renderers={{link: linkRenderer, strong: boldRenderer, heading: boxedIsiSpecialRenderer}} />
          </Grid>
        </Row> :
        <Row className={expanded ? css(styleSheet.boxedIsi, styleSheet.isiExpanded) : css(styleSheet.boxedIsi, styleSheet.isiShrunk)}>
          {topRightLinkElements}
          <ReactMarkdown escapeHtml={false} source={source} renderers={{link: linkRenderer, strong: boldRenderer, heading: boxedIsiSpecialRenderer}} />
        </Row>
    );
  }
}

BoxedIsi.protoTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  source: PropTypes.any.isRequired,
  topRightLink: PropTypes.object.isRequired,
  fixed: PropTypes.object.isRequired,
  expandable: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
};

export default BoxedIsi;
