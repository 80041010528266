import {createSelector} from 'reselect';

const getShowErrorState = (state) => state.claimNOC.get('_metadata').get('showErrorAlert');
const getShowNoRowsState = (state) => state.claimNOC.get('_metadata').get('showNoRowsAlert');

export const getError = (state) => state.claimNOC.get('_metadata').get('error');
export const getInProgress = (state) => !!state.claimNOC.get('_metadata').get('inProgress');

export const getShowErrorAlert = createSelector(
  [getShowErrorState, getError],
  (showErrorAlert, error) => showErrorAlert && !!error
);

export const getShowNoRowsAlert = createSelector(
  [getShowNoRowsState, getError],
  (showNoRowsAlert, error) => showNoRowsAlert && !error
);

