import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';

import logoAssets from 'assets/assets.js';
import {logout} from 'actions/login';

import Faq from 'components/Faq';
import Login from 'components/Login';
import LinkNOC from 'components/LinkNOC';
import Footer from 'components/Footer';
import Header from 'components/Header';
import {RedirectModal, LoginModal} from 'components/Modals';

import './styles.scss';

export class ClaimNOCPage extends Component {
  constructor(props) {
    super(props);

    const {location, settings} = props;

    const showLoginModal = settings.loginModal.disabled
      ? false
      : (settings.authenticate ? (location.pathname === '/login' ? true : false) : true);

    this.state = {
      showLoginModal,
      showRedirectModal: false,
      authenticated: false,
    };

    this._modalHandler = this._modalHandler.bind(this);
  }

  _modalHandler(type) {
    switch (type) {
      case 'hideLogin':
        this.setState({showLoginModal: false});
        break;

      case 'redirect':
        this.setState({showLoginModal: false, showRedirectModal: true});
        break;

      case 'logout':
        this.props.dispatchLogout();
        this.props.history.push('/');
        break;

      case 'exit':
        window.location.assign(this.props.settings.page.redirectLink);
        break;

      default:
        break;
    }
  }

  render() {
    const {
      location,
      authenticated,
      settings,
      styleSheet,
    } = this.props;

    const {showLoginModal, showRedirectModal} = this.state;

    const currentRoute = location.pathname;

    if (authenticated && currentRoute === '/login') {
      return (<Redirect to={{
        pathname: '/',
        search: this.props.location.search,
        state: {from: this.props.location}}}
      />);
    }

    return (
      <div>
        <LoginModal
          settings={settings}
          styleSheet={styleSheet}
          showLoginModal={showLoginModal}
          handleHide={() => this._modalHandler('hideLogin')}
          handleRedirect={() => this._modalHandler('redirect')}
        />
        <RedirectModal
          settings={settings}
          styleSheet={styleSheet}
          showRedirectModal={showRedirectModal}
          handleExit={() => this._modalHandler('exit')}
        />

        <Header
          logoAssets={logoAssets}
          settings={settings}
          styleSheet={styleSheet}
          authenticated={authenticated}
          handleLogout={() => this._modalHandler('logout')}
        />

        {currentRoute === '/login' && <Login styleSheet={styleSheet}/>}
        {currentRoute === '/faq' && <Faq styleSheet={styleSheet}/>}
        {currentRoute === '/' && <LinkNOC styleSheet={styleSheet} showTable={!this.state.showLoginModal && !this.state.showRedirectModal}/>}

        <Footer settings={settings} styleSheet={styleSheet} logoAssets={logoAssets}/>
      </div>
    );
  }
}

ClaimNOCPage.propTypes = {
  authenticated: PropTypes.bool,
  history: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  dispatchLogout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {login, settings} = state;

  return {
    authenticated: login.get('authenticated'),
    settings: settings.get('settings').toJS(),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLogout: () => dispatch(logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ClaimNOCPage));
