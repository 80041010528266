import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {getAuthenticated} from 'selectors/login';

const PrivateRoute = ({component: Component, ...rest}) => {
  const {authenticated, ...restProps} = rest;

  return (
    <Route {...rest} render={(props) => (
      authenticated
        ? (<Component {...restProps} {...props}/>)
        : (<Redirect to={{
          pathname: '/login',
          search: props.location.search,
          state: {from: props.location}}}
        />)
    )}
    />
  );
};

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    authenticated: getAuthenticated(state),
    ...ownProps,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
